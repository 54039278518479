import React from "react";
import { getStatisticsData, getNextLevelData } from "../../http/api.js";
import CommonUtils from '../../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, notification, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "../SearchItem.js";
import dayjs from 'dayjs';
import MyOpenMenu from "../components/MyOpenMenu.js";
import MyTable from "../components/MyTable.js";
import ReactEcharts from 'echarts-for-react';
import { statisticsChart, statisticsPieChart } from "../echarts/charts.jsx";


class OzonTrendPage extends React.Component {
  constructor(props) {
    super(props);
    var categoryData = CommonUtils.getLocalStorage("categoryMiniData")
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      tableData: [],
      tableData_total: 0,
      showChart: statisticsChart,
      statisticsPieChart: statisticsPieChart,
      categoryData: categoryData,
      searchCategory: null,//搜索条件变了之后，page的切换到第一页，靠这个
      exchangeRate: CommonUtils.getLocalStorage("exchangeRate"),
      lastRequestTime: new Date().getTime(),
      nextLevelData: [],//饼图数据
      legendSelected:null,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部
    this.getStatisticsDataFun()//获取统计数据

    this.getNextLevelDataFun()//查询饼图数据


    var showTip = CommonUtils.checkRoleStatistics({})//验证用户的统计分析页面的权限，并返回提示文字
    if (showTip) {
      this.openNotification(showTip)
    }




  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  //弹窗右上角提示框
  openNotification = (showText) => {
    notification.info({
      message: '提示',
      description: showText,
      style: { top: "50px" },
      duration: 8,//默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
      showProgress: true,//显示自动关闭通知框的进度条
      btn: <><Button href="/price?resources=2" target="_blank" type="primary" size="small" className="colorWhite">订购升级</Button></>
    });
  };


  myOpenMenuClickFun = (data) => {
    //data： 
    // {
    //     "category1Id": 17027915,
    //     "category2Id": 17027916,
    //     "category3Id": 95051
    // }


    this.getStatisticsDataFun(data)
    this.getNextLevelDataFun(data)//查询饼图数据
  }

  //获取统计数据
  getStatisticsDataFun = (data) => {
    var { showChart, exchangeRate } = this.state;
    let _this = this;
    if (!data) {
      data = {}
    }
    var params = {
      "category1Id": data.category1Id ? data.category1Id : 0,
      "category2Id": data.category2Id ? data.category2Id : 0,
      "category3Id": data.category3Id ? data.category3Id : 0,
    }

    params["isOneDay"] = false

    //等数据够多了，给图表添加dataZoom 组件   https://echarts.apache.org/zh/option.html#dataZoom

    //查询柱状图数据
    getStatisticsData(params).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        //汇率转化
        // console.log("exchangeRate==>", exchangeRate)
        var exchangeRate_RUB = null;
        if (exchangeRate && exchangeRate.RUB) {
          exchangeRate_RUB = exchangeRate.RUB.rate
        }
        var xdata = []
        var y1data = []
        var y2data = []
        var y3data = []
        var y4data = []

        var previous_item = null

        resData.map(item => {
          // y1data.push(CommonUtils.roundToDecimalPlace(item.soldCount / 10000, 2))
          // if(exchangeRate_RUB){//汇率转化
          //   y2data.push(CommonUtils.roundToDecimalPlace(item.soldMoneyCount / exchangeRate_RUB / 10000, 2)) 
          // }else{
          //   y2data.push(item.soldMoneyCount) 
          // }
          // y3data.push(CommonUtils.roundToDecimalPlace(item.openCount / 10000, 2))
          // y4data.push(CommonUtils.roundToDecimalPlace(item.searchCount / 10000, 2))

          //修改min 和max  让图片更明显  --待测试


          var agv_number = 3 //差值分额
          if (previous_item) {//有上一个数据
            var y1data_item = item.soldCount / 28 + (item.soldCount - previous_item.soldCount) / agv_number
            if(y1data_item <= 0){
              return
            }
            y1data_item = y1data_item > 0 ? CommonUtils.roundToDecimalPlace(y1data_item, 0) : 10

            var y2data_item = item.soldMoneyCount / 28 + (item.soldMoneyCount - previous_item.soldMoneyCount) / agv_number

            y2data_item = item.soldMoneyCount / item.soldCount * y1data_item //用单价乘以数量
            if (exchangeRate_RUB) {//汇率转化
              //console.log("exchangeRate_RUB===>",exchangeRate_RUB,y2data_item)
              y2data_item = CommonUtils.roundToDecimalPlace(y2data_item / exchangeRate_RUB, 0)
            }
            y1data.push(y1data_item)
            y2data.push(y2data_item)

            var y3data_item = item.openCount / 28 + (item.openCount - previous_item.openCount) / agv_number
            y3data_item = y3data_item > 0 ? CommonUtils.roundToDecimalPlace(y3data_item, 0) : 10
            y3data.push(y3data_item)

            var y4data_item = item.searchCount / 28 + (item.searchCount - previous_item.searchCount) / agv_number
            y4data_item = y4data_item > 0 ? CommonUtils.roundToDecimalPlace(y4data_item, 0) : 10
            y4data.push(y4data_item)
            previous_item = item

          } else {
            var y1data_item = CommonUtils.roundToDecimalPlace(item.soldCount / 28, 0)
            y1data.push(y1data_item)

            var y2data_item = item.soldMoneyCount / item.soldCount * y1data_item  //用单价乘以数量
            y2data_item = CommonUtils.roundToDecimalPlace(y2data_item, 0)
            if (exchangeRate_RUB) {//汇率转化
              y2data_item = CommonUtils.roundToDecimalPlace(y2data_item / exchangeRate_RUB, 0)
            }

            y2data.push(y2data_item)
            y3data.push(CommonUtils.roundToDecimalPlace(item.openCount / 28, 0))
            y4data.push(CommonUtils.roundToDecimalPlace(item.searchCount / 28, 0))
            previous_item = item
          }

          xdata.push(item.createdTime.slice(0, 10));
        })

        showChart.xAxis.data = xdata;
        showChart.series[0].data = y1data;
        showChart.series[1].data = y2data;
        showChart.series[2].data = y3data;
        showChart.series[3].data = y4data;

        _this.setState({
          showChart: showChart
        })

        //message.success("请求成功！")
      } else {
        _this.setState({
          statisticsData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("请求失败，请重试！")
    })
  }

  //查询饼图数据 
  getNextLevelDataFun = (data) => {
    let _this = this;
    if (!data) {
      data = {}
    }
    var params = {
      "category1Id": data.category1Id ? data.category1Id : 0,
      "category2Id": data.category2Id ? data.category2Id : 0,
      "category3Id": data.category3Id ? data.category3Id : 0,
    }

    getNextLevelData(params).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          nextLevelData: resData
        })
      } else {
        _this.setState({
          nextLevelData: []
        })
      }
    })
  }

  //饼图的format （深拷贝之后， formatter函数会失效，函数拷贝不了）
  pieTooltipFormatter = (params) => {
    var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: bold">' + params.seriesName + '(月度)</span>' + "</br>"
    // var showValue = params.value > 10000 ? CommonUtils.roundToDecimalPlace(params.value / 10000, 2) + ' 万元' : params.value + ' 元'
    var showValue = ""
    if (params.value > 100000000) {
      showValue = CommonUtils.roundToDecimalPlace(params.value / 100000000, 2) + ' 亿'
    } else if (params.value > 10000) {
      showValue = CommonUtils.roundToDecimalPlace(params.value / 10000, 2) + ' 万'
    } else {
      showValue = params.value + ''
    }

    result += params.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + params.name + '</span>' + " : "
      + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
    return result;
  }

  //最上面的柱状图的图例显示
  legendselectchangedFun = (event) =>{
    // console.log('图例 ' + event.name + ' 的选中状态变为：' , event.selected);
    this.setState({
      "legendSelected":event.selected
    })
  }

  render() {
    const { spinning, showModel_, exchangeRate, showChart, statisticsPieChart, categoryData, nextLevelData, legendSelected,} = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    if(legendSelected){
      showChart.legend.selected = legendSelected
    }

    var exchangeRate_RUB = null;
    if (exchangeRate && exchangeRate.RUB) {
      exchangeRate_RUB = exchangeRate.RUB.rate
    }



    //深拷贝之后， formatter函数会失效，函数拷贝不了
    var pieOption1 = JSON.parse(JSON.stringify(statisticsPieChart))
    var pieOption2 = JSON.parse(JSON.stringify(statisticsPieChart))
    var pieOption3 = JSON.parse(JSON.stringify(statisticsPieChart))

    pieOption1.series[0].name = "销量"
    pieOption2.series[0].name = "销售额"
    pieOption3.series[0].name = "曝光量"

    pieOption1.graphic.style.text = "销量"
    pieOption2.graphic.style.text = "销售额"
    pieOption3.graphic.style.text = "曝光量"

    var dataList_soldCount = []
    var dataList_soldMoneyCount = []
    var dataList_openCount = []
    nextLevelData.map(item => {
      var categoryName = item.category1
      if (item.category2) {
        categoryName = item.category2
      }
      if (item.category3) {
        categoryName = item.category3
      }

      var soldMoneyCount = item.soldMoneyCount
      if (exchangeRate_RUB) {//汇率转化
        soldMoneyCount = CommonUtils.roundToDecimalPlace(soldMoneyCount / exchangeRate_RUB, 0)
      }

      dataList_soldCount.push({ value: item.soldCount, name: categoryName })
      dataList_soldMoneyCount.push({ value: soldMoneyCount, name: categoryName })
      dataList_openCount.push({ value: item.openCount, name: categoryName })
    })

    pieOption1.series[0].data = dataList_soldCount
    pieOption2.series[0].data = dataList_soldMoneyCount
    pieOption3.series[0].data = dataList_openCount

    pieOption1.tooltip.formatter = this.pieTooltipFormatter
    pieOption2.tooltip.formatter = this.pieTooltipFormatter
    pieOption3.tooltip.formatter = this.pieTooltipFormatter

    //竖向柱状图
    var barOption4 = {
      title: {
        show: false,
        text: ' ',
        subtext: ' '
      },
      grid: {
        top: '30px',
        // bottom:10,
        right: '10%',
        left: '10%',
        // containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
          color: '#000',
        },
        // width: '50px',
        extraCssText: 'box-shadow:0px 15px 30px 0px rgba(0, 0, 0, 0.14);box-radius: 6px;padding:10px',
        // borderRadius: '6px',
        // extraCssText: 'box-radius: 6px;',
        // "时间: " + params[0].name + "</br>";
        formatter: function (params) {
          // console.log("params===>",params)
          var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: bold">' + params[0].name + ':</span>' + "</br>"
          params.forEach(function (item) {
            var showValue = ""
            if (item.value > 100000000) {
              showValue = CommonUtils.roundToDecimalPlace(item.value / 100000000, 2) + ' 亿元'
            } else if (item.value > 10000) {
              showValue = CommonUtils.roundToDecimalPlace(item.value / 10000, 2) + ' 万元'
            } else {
              showValue = item.value + ' 元'
            }
            result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
              + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
          });
          return result;
        }
      },
      legend: {
        show: false,
        data: ['总量']
      },
      color: ['#0AD2FF', '#085EEB', '#00BABA', '#6B74FF'],
      toolbox: {
        show: false
      },
      dataZoom: {
        show: false,
        start: 0,
        end: 100
      },
      calculable: true,
      xAxis: { show: false, },
      yAxis: [
        {
          type: 'category',
          data: []//['巴西','印尼','美国','印度','中国','巴西','印尼','美国','印度','中国','巴西','印尼','美国','印度','中国','总销售额(万)'],

        }
      ],
      series: [
        {
          name: '销售额(月度)',
          type: 'bar',
          data: [],//[18203, 23489, 29034, 104970, 131744,18203, 23489, 29034, 104970, 131744,18203, 23489, 29034, 104970, 131744, 330230],
          barMaxWidth: 20,
          // itemStyle: {
          //   // 使用 callback 函数为每个柱子设置不同的颜色
          //   color: function (params) {
          //     console.log("params===>",params)
          //     // 这里的 params.dataIndex 是当前数据项的索引
          //     // var colorList = ['#c23531', 'green', 'blue', 'purple', 'orange', 'grey', 'cyan'];
          //     // var colorList = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
          //     //   '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
          //     //   '#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
          //     //   '#6b8e23', '#ff00ff', '#3cb371', '#b8860b', '#30e0e0'];
          //     var colorList = ['#30dae5',  '#33a7e2', '#7386f3',  '#467ae6',  '#3e5aec',  '#8050eb',  '#7f50ed','#e74a9a', '#fa5a59',  '#fb8749',  '#f7bc5f',  '#faef4e',  '#d3ee5c',  '#b1ef15',  '#83e740',  '#36df2d',  '#1fe7ad'  ];
          //     return colorList[params.dataIndex % colorList.length];
          //   }
          // },
          label: {
            show: true,
            // position: 'inside',
            position: 'right',
            formatter: (params) => {
              // console.log("params==>",params)
              var showValue = ""
              if (params.data.value > 100000000) {
                showValue = CommonUtils.roundToDecimalPlace(params.data.value / 100000000, 2) + ' 亿元'
              } else if (params.data.value > 10000) {
                showValue = CommonUtils.roundToDecimalPlace(params.data.value / 10000, 2) + ' 万元'
              } else {
                showValue = params.data.value + ' 元'
              }
              return showValue
            }
          },
        },
      ]
    }
    var verticalBar_yAxisdata = []
    var verticalBar_seriesdata = []

    var colorList = ['#30dae5', '#33a7e2', '#7386f3', '#467ae6', '#3e5aec', '#8050eb', '#7f50ed', '#e74a9a', '#fa5a59', '#fb8749', '#f7bc5f', '#faef4e', '#d3ee5c', '#b1ef15', '#83e740', '#36df2d', '#1fe7ad'];
    var nextLevelData_t = JSON.parse(JSON.stringify(nextLevelData))
    nextLevelData_t = CommonUtils.sortByValue(nextLevelData_t, "soldMoneyCount")//排序
    nextLevelData_t.map((item, index) => {
      var categoryName = item.category1
      if (item.category2) {
        categoryName = item.category2
      }
      if (item.category3) {
        categoryName = item.category3
      }

      var soldMoneyCount = item.soldMoneyCount
      if (exchangeRate_RUB) {//汇率转化
        soldMoneyCount = CommonUtils.roundToDecimalPlace(soldMoneyCount / exchangeRate_RUB, 0)
      }
      verticalBar_yAxisdata.push(categoryName)
      // verticalBar_seriesdata.push(soldMoneyCount)
      //颜色从上面往下固定顺序
      verticalBar_seriesdata.push({ value: soldMoneyCount, itemStyle: { color: colorList[(nextLevelData_t.length - index - 1) % colorList.length] } })
    })

    barOption4.yAxis[0].data = verticalBar_yAxisdata;
    barOption4.series[0].data = verticalBar_seriesdata;




    return (
      <React.Fragment>
        <MyOpenMenu categoryData={categoryData} key={categoryData} clickFun={this.myOpenMenuClickFun} />

        <div>
          <div className="ozonTrendpageTitle">类目销售统计数据</div>
          <ReactEcharts ref='echarts_ozonTrend_bar' option={showChart} key={JSON.stringify(showChart)} style={{ height: 420 }} 
          onEvents={{ 'legendselectchanged': this.legendselectchangedFun }}
          />
        </div>
        {
          nextLevelData && nextLevelData.length > 0 ?
            <>
              <div style={{ marginTop: "30px" }}>
                <div className="ozonTrendpageTitle">子类目月销售占比</div>
                <Row justify={"left"} align={"middle"} wrap>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} >
                    <ReactEcharts option={pieOption1} key={JSON.stringify(pieOption1)} style={{ height: 420 }} />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} >
                    <ReactEcharts option={pieOption2} key={JSON.stringify(pieOption2)} style={{ height: 420 }} />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} >
                    <ReactEcharts option={pieOption3} key={JSON.stringify(pieOption3)} style={{ height: 420 }} />
                  </Col>
                </Row>
              </div>
              <div className="ozonTrendpageTitle">子类目月销售额</div>
              <div>
                <ReactEcharts ref='echarts_react_horizontal' option={barOption4}
                  style={{ height: verticalBar_seriesdata.length * 25 > 300 ? verticalBar_seriesdata.length * 25 : 300 }} />
              </div>
            </>
            : null
        }





        <Modal title="添加xpath" open={showModel_} footer={null} onCancel={() => this.setStateKeyValue("showModel_", false)}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.addAuthShopFun}
            autoComplete="off"
          >
            <Form.Item label="店铺名称" name="shop_name"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input placeholder="如：店铺1-ozon" />
            </Form.Item>

            <Form.Item label="Client ID" name="shopId"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input type="number" />
            </Form.Item>
            <Form.Item label="API 密钥" name="accessToken"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              {/* <Input.Password /> */}
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">
                添加授权
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(OzonTrendPage);
