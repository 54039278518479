import React from "react";
import { getRateCategory } from "../../http/api.js";
import CommonUtils from '../../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, notification,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "../SearchItem.js";
import dayjs from 'dayjs';
import MyOpenMenu from "../components/MyOpenMenu.js";
import MyTable from "../components/MyTable.js";


class OzonPotentialCategoryPage extends React.Component {
  constructor(props) {
    super(props);
    var categoryData = CommonUtils.getLocalStorage("categoryMiniData")
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      tableData: [],
      tableData_total: 0,
      categoryData: categoryData,
      searchCategory: null,//搜索条件变了之后，page的切换到第一页，靠这个
      exchangeRate: CommonUtils.getLocalStorage("exchangeRate"),
      lastRequestTime: new Date().getTime(),
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部
    this.getRateCategoryFun()
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  //弹窗右上角提示框
  openNotification = (showText) => {
    notification.info({
      message: '提示',
      description: showText,
      style: { top: "50px" },
      duration: 8,//默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
      showProgress: true,//显示自动关闭通知框的进度条
      btn:<><Button href="/price?resources=2" target="_blank" type="primary" size="small" className="colorWhite">订购升级</Button></>
    });
  };

  myOpenMenuClickFun = (data) => {
    //data： 
    // {
    //     "category1Id": 17027915,
    //     "category2Id": 17027916,
    //     "category3Id": 95051
    // }
    this.setState({
      searchCategory: JSON.stringify(data) //搜索条件变了之后，page的切换到第一页，靠这个
    })
    this.getRateCategoryFun(data)
  }

  //表格分页点击事件
  tablePageChangeFun = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getRateCategoryFun(paramsParams) // 
  }


  //查询潜力类目
  getRateCategoryFun = (params) => {
    let _this = this;
    const { exchangeRate } = this.state;
    if (!params) {
      params = {}
    }

    // var params = {
    //   "category1Id": data.category1Id ? data.category1Id : 0,
    //   "category2Id": data.category2Id ? data.category2Id : 0,
    //   "category3Id": data.category3Id ? data.category3Id : 0,
    // }

    var lastRequestTime = new Date().getTime()
    //缓存参数，分页时候使用
    this.setState({
      "paramsParams": params,
      spinning: true,
      lastRequestTime: lastRequestTime
    })

    var showTip = CommonUtils.checkRoleStatistics(params)//验证用户的统计分析页面的权限，并返回提示文字
    if (showTip) {
      this.openNotification(showTip)
    }

    getRateCategory(params).then(res => {
      _this.setStateKeyValue("spinning", false)
      if (res && res.data && res.data.success) {
        let resData = res.data.data.records;
        let tableData_total = res.data.data.total;
        var tableData = []
        //汇率转化
        // console.log("exchangeRate==>", exchangeRate)
        var exchangeRate_RUB = null;
        if (exchangeRate && exchangeRate.RUB) {
          exchangeRate_RUB = exchangeRate.RUB.rate
        }
        resData.map(item => {
          var categoryText = ""
          if (item.category1) {
            categoryText = item.category1
          }
          if (item.category2) {
            categoryText += ">" + item.category2
          }
          if (item.category3) {
            categoryText += ">" + item.category3
          }

          var soldMoneyCount = item.soldMoneyCount
          if (exchangeRate_RUB) {
            soldMoneyCount = soldMoneyCount / exchangeRate_RUB
          }

          tableData.push({
            "categoryText": categoryText,
            "soldCount": CommonUtils.getChineseNumber(item.soldCount),
            "soldMoneyCount": CommonUtils.getChineseNumber(soldMoneyCount),
            "openCount": CommonUtils.getChineseNumber(item.openCount),
            "searchCount": CommonUtils.getChineseNumber(item.searchCount),
            "avgPrice": CommonUtils.getChineseNumber(soldMoneyCount / item.soldCount) + "元",
            "avgGmvOnAccDays": CommonUtils.getChineseNumber(soldMoneyCount / 28),
            "rate": item.rate
          })

        })

        _this.setState({
          tableData: tableData,
          tableData_total: tableData_total,
        })


      } else {
        _this.setState({
          tableData: [],
          tableData_total: 0,
        })
      }
    })
  }

  render() {
    const { spinning, showModel_, tableData, tableData_total, categoryData, searchCategory } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var allColumns = [
      {
        title: <Tooltip title="商品类目。">类目</Tooltip>,
        dataIndex: 'categoryText',
        key: 'categoryText',
        width: 80,
        // ellipsis:true,//超过宽度将自动省略
        // responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text, record) => <>{text ? text.replaceAll(" ", "").replaceAll(">>", ">") : null}</>
      },
      {
        title: <Tooltip title="潜力值 = 搜索量 / 销量。在一定程度上反应，需求和供给的关系。潜力值越大，需求越得不到满足（仅供参考）。">潜力值
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,//
        dataIndex: 'rate',
        key: 'rate',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "rate"),
        //responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: <Tooltip title="销量：前28天已经订购的总数量。单位：件">销量
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,//
        dataIndex: 'soldCount',
        key: 'soldCount',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "soldCount"),
        //responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: <Tooltip title="在搜索结果和目录中的浏览量。">搜索量
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,//   在搜索结果和目录中的浏览量
        dataIndex: 'searchCount',
        key: 'searchCount',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "searchCount"),
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: <Tooltip title="前28天内订购商品金额总和。单位：人民币元">销售额
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,// 
        dataIndex: 'soldMoneyCount',
        key: 'soldMoneyCount',
        width: 60,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "soldMoneyCount"),
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },

      {
        title: <Tooltip title="商品明细页面浏览量 （客户打开商品明细页面的数量）。">点击量
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,//商品卡片浏览量 （打开了卡片）
        dataIndex: 'openCount',
        key: 'openCount',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "openCount"),
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },

      {
        title: <Tooltip title="单位：人民币元">均价
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,// 
        dataIndex: 'avgPrice',
        key: 'avgPrice',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "avgPrice"),
        // responsive: ['lg'],//响应式配置。未设置则始终可见
      },

      {
        title: <Tooltip title="单位：人民币元">日均销售额
          <svg className="tableInfoIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3148" width="12" height="12"><path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z" fill="#bfbfbf" p-id="3149"></path></svg>
        </Tooltip>,// 
        dataIndex: 'avgGmvOnAccDays',
        key: 'avgGmvOnAccDays',
        width: 50,
        sorter: (a, b) => CommonUtils.sorterFun(a, b, "avgGmvOnAccDays"),
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },

    ];


    return (
      <React.Fragment>
        <MyOpenMenu category3_hide categoryData={categoryData} key={categoryData} clickFun={this.myOpenMenuClickFun} />
        <Spin tip="查询中..." spinning={spinning}>
          <MyTable
            showKeyList={["categoryText", 'rate', "soldCount", "soldMoneyCount", "openCount", "searchCount", "avgPrice", "avgGmvOnAccDays"]}
            allColumns={allColumns}
            tableName="OzonPotentialCategoryPage"
            dataSource={tableData}
            size="small"//表格大小 默认large   large | middle | small
            key={searchCategory}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100], defaultPageSize: 10,
              total: tableData_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.tablePageChangeFun
            }}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}

          />
        </Spin>

        <Modal title="添加xpath" open={showModel_} footer={null} onCancel={() => this.setStateKeyValue("showModel_", false)}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.addAuthShopFun}
            autoComplete="off"
          >
            <Form.Item label="店铺名称" name="shop_name"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input placeholder="如：店铺1-ozon" />
            </Form.Item>

            <Form.Item label="Client ID" name="shopId"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input type="number" />
            </Form.Item>
            <Form.Item label="API 密钥" name="accessToken"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              {/* <Input.Password /> */}
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">
                添加授权
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(OzonPotentialCategoryPage);
