import React from "react";
import { getFeedback } from "../http/api";
import CommonUtils from '../http/Common';
import { Table, Divider, Button, Avatar, Image, Space, Input, Modal, Typography, message, } from 'antd';


class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10, //每页条数
      total: 1,//总条数
      tableData: [],

      currentPage_idea: 1,
      pageSize_idea: 10, //每页条数
      total_idea: 1,//总条数
      tableData_idea: [],

      visible: false,
      activeImg: null,

      isModalOpen: false,
      ideaLog: null,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    this.getFeedbackFun();//上传失败
    this.getFeedbackFun_idea();//建议

  }

  getFeedbackFun = () => {
    let _this = this;
    const { currentPage, pageSize } = this.state;

    getFeedback({ type: "badurl", current: currentPage, size: pageSize }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        this.setState({
          total: resData.total,//总页数  进一法
          tableData: resData.records,
        })

        message.success("请求成功！");
      } else {
        _this.setState({
          tableData: [],
        })
      }
    });
  }

  getFeedbackFun_idea = () => {
    let _this = this;
    const { currentPage_idea, pageSize_idea } = this.state;

    getFeedback({ type: "idea", current: currentPage_idea, size: pageSize_idea }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        this.setState({
          total_idea: resData.total,//总页数  进一法
          tableData_idea: resData.records,
        })

        message.success("请求成功！");
      } else {
        _this.setState({
          tableData_idea: [],
        })
      }
    });
  }

  onChangeFun = (page, pageSize) => {
    this.setState({
      currentPage: page,
      pageSize: pageSize, //每页条数
    }, this.getFeedbackFun)
  }

  onChangeFun_idea = (page, pageSize) => {
    this.setState({
      currentPage_idea: page,
      pageSize_idea: pageSize, //每页条数
    }, this.getFeedbackFun_idea)

  }

  copyUrlFun = () => {
    const { tableData } = this.state;
    var urls = "";
    tableData.map(i => {
      if (!urls.includes(i["content"])) {
        urls += i["url"] + "\n"
      }
    })
    CommonUtils.copyData(urls)

  }

  showImg = (img_path) => {
    this.setState({
      visible: true,
      activeImg: img_path
    })
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  showLogModal = (log) => {
    this.setState({
      isModalOpen: true,
      ideaLog: log,
    })
  }


  render() {
    const { currentPage, pageSize, total, tableData, tableData_idea, currentPage_idea, pageSize_idea, total_idea, visible, activeImg, isModalOpen, ideaLog, } = this.state;


    const columns = [
      {
        title: '用户ID',
        dataIndex: 'userId',
        align: "center",
        width: 150,
      },
      // {
      //   title: '类型',
      //   dataIndex: 'type',
      //   align: "center",
      //   width: 150,
      // },
      {
        title: '提交时间',
        dataIndex: 'createdTime',
        align: "center",
        width: 200,
      },
      {
        title: '平台',
        dataIndex: 'platform',
        align: "center",
        width: 150,
      },
      {
        title: '平台类别',
        dataIndex: 'log',
        align: "center",
        width: 200,
        ellipsis: true,
        render: (text) => {
          if (!text) {
            return null
          } else {
            return <span>{text.replaceAll(",", " >> ")}</span>
          }
        }
      },
      {
        title: 'url',
        dataIndex: 'url',
        align: "center",
        ellipsis: true,
      },
      {
        title: '内容',
        dataIndex: 'content',
        align: "center",
        ellipsis: true,
      },

    ];
    const columns_idea = [
      {
        title: '用户ID',
        dataIndex: 'userId',
        align: "center",
        width: 150,
      },
      // {
      //   title: '类型',
      //   dataIndex: 'type',
      //   align: "center",
      //   width: 150,
      // },
      {
        title: '提交时间',
        dataIndex: 'createdTime',
        align: "center",
        width: 200,
      },
      {
        title: '图片',
        dataIndex: 'pictureName',
        align: "center",
        width: 200,
        render: (text) => {
          if (!text) {
            return ""
          }
          var imgs = text.split(',')
          return <Avatar.Group size="large">
            {
              imgs.map(img => {
                // var img_path = "https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/feedback/" + img + ".jpg"
                var img_path = "https://baodanerp.cn/erpData/feedback/" + img + ".jpg"
                return <Avatar key={img_path} src={img_path} onClick={() => this.showImg(img_path)} />
              })
            }
          </Avatar.Group>
        },
      },
      {
        title: '内容',
        dataIndex: 'content',
        align: "center",
        ellipsis: true,
      },
      {
        title: '日志',
        dataIndex: 'log',
        align: "center",
        ellipsis: true,
        render: (text) => {
          if (!text) {
            return ""
          } else {
            return <span><Button type="primary" size="small" onClick={() => this.showLogModal(text)} style={{marginRight:"5px"}}>查看</Button>{text.substring(0, 100)} </span>
          }
        }
      },
    ];

    var scaleStep = 0.5;

    return (
      <React.Fragment>
        <div className=" ">
          <Divider>投诉建议</Divider>

          <Image
            width={200}
            style={{
              display: 'none',
            }}
            preview={{
              visible,
              scaleStep,
              src: activeImg,
              onVisibleChange: (value) => {
                this.setStateKeyValue("visible", value);
              },
            }}
          />
          <Modal title="日志" width={1200} open={isModalOpen} onCancel={() => this.setStateKeyValue("isModalOpen", false)} footer={null}>
            <Space direction="vertical">
              {
                ideaLog ? ideaLog.split('\r\n').map(text => {
                  return <Typography.Text>{text}</Typography.Text>
                })
                  : null
              }
            </Space>
          </Modal>

          <Table
            columns={columns_idea}
            dataSource={tableData_idea}
            size="small"
            pagination={{
              current: currentPage_idea,
              pageSize: pageSize_idea,
              total: total_idea,
              onChange: this.onChangeFun_idea,
            }}
            scroll={{
              y: 400,
            }}
          />

          <Divider>提交失败URL</Divider>
          <Button key="copy" type="primary" onClick={this.copyUrlFun}>复制表中URL</Button>
          <Table
            columns={columns}
            dataSource={tableData}
            size="small"
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              onChange: this.onChangeFun,
              pageSizeOptions: [10, 20, 50, 100, 200, 300, 500, 1000],
            }}
            scroll={{
              y: 400,
            }}
          />

        </div>
      </React.Fragment>
    );
  }
}

export default Feedback;