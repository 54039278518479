import React from "react";
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import CommonUtils from '../../http/Common';
import BannerItem from './BannerItem';
import { Image, Button, Carousel } from 'antd';
const BgElement = Element.BgElement;

// 调用例子
// import MyImageBanner from "./components/MyImageBanner.js";
// var bannerData = [
// {
//     "imgs":["/img/pro/banner1.png","/img/pro/banner2.png"],
//     "btn1":{"label":"免费试用","url":  window.location.origin + "/download"},
//     "btn2":{"label":"了解详情","url": window.location.origin +"/price"}
//  },
// ]

// <MyImageBanner bannerData={bannerData} height={"50vh"} />


class MyImageBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerData: props.bannerData ? props.bannerData : [],
        };
    }

    componentDidMount() {
        let _this = this;
    }


    imgClickFun = (url) => {
        if (url && url != "") {
            window.open(url, "_blank")
            // window.open(url, "_self")
        }
    }

    render() {
        const { bannerData } = this.state;

        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        var size_small_or_large = isPhone ? "small" : "large"  //large  middle   small  

        return (
            <React.Fragment>
                {/* <BannerAnim prefixCls="banner-user" autoPlay={true} style={ isPhone ? { height: window.innerWidth/1.78 +"px" } : { height: window.innerWidth/4.8 +"px" }}>
                    {
                        bannerData.map((data, index) => {
                            // console.log("data==>",data)
                            return (
                                <Element prefixCls="banner-user-elem" key={index} onClick={() => this.imgClickFun(data.url)}>

                                   
                                    <div className="bannerBtnBox">
                                        {data.btn1 ?
                                            <Button className={data.btn1.className ? "banner_btn " + data.btn1.className : "banner_btn"} size={size_small_or_large} 
                                            onClick={() => this.imgClickFun(data.btn1.url)}>{data.btn1.text}</Button>
                                            : null
                                        }
                                        {data.btn2 ?
                                            <Button className={data.btn2.className ? "banner_btn " + data.btn2.className : "banner_btn"} size={size_small_or_large} 
                                            onClick={() => this.imgClickFun(data.btn2.url)}>{data.btn2.text}</Button>
                                            : null
                                        }
                                    </div>

                                    <Image src={data.img} preview={false} ></Image>
                                </Element>
                            )


                        })
                    }
                </BannerAnim> */}

                <Carousel autoplay>
                    {
                        bannerData.map((data, index) => {
                            // console.log("data==>",data)
                            return (
                                <div className="bannerBtnBoxP" key={index} onClick={() => this.imgClickFun(data.url)}>
                                    <div className="bannerBtnBox">
                                        {data.btn1 ?
                                            <Button className={data.btn1.className ? "banner_btn " + data.btn1.className : "banner_btn"} size={size_small_or_large}
                                                onClick={() => this.imgClickFun(data.btn1.url)}>{data.btn1.text}</Button>
                                            : null
                                        }
                                        {data.btn2 ?
                                            <Button className={data.btn2.className ? "banner_btn " + data.btn2.className : "banner_btn"} size={size_small_or_large}
                                                onClick={() => this.imgClickFun(data.btn2.url)}>{data.btn2.text}</Button>
                                            : null
                                        }
                                    </div>

                                    <Image src={data.img} preview={false} width={"100%"} height={"100%"}></Image>
                                </div>
                            )


                        })
                    }
                </Carousel>
            </React.Fragment>
        );
    }
}

export default MyImageBanner;