import React from "react";
import {
    getVersion, setVersion, setDebug, getAllCategory, ozonFreshAllCategory, refreshAllTiktokType, getAllOzonAnalysisData, getUpdateDateLongTime,
    setUpdateDateLongTime, getCookieList, setCookieList,
} from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Form, List, Input, Radio, Space, message, InputNumber, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version: null,
            new_version: null,
            phone: null,
            updateDateLongTime: null,
            datePickerValue: null,
            cookieList: [],
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部
        this.getVersionFun() //获取版本号
        this.getUpdateDateLongTimeFun() //获取ozon分析数据的最后时间
        this.getCookieListFun() //获取ozon cookie


    }



    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //获取ozon分析数据的最后时间
    getUpdateDateLongTimeFun = () => {
        let _this = this;
        getUpdateDateLongTime({}).then(res => {
            if (res && res.data) {
                _this.setState({
                    updateDateLongTime: res.data.data
                })
            } else {
                _this.setState({
                    updateDateLongTime: null,
                })
            }
        });
    }

    //更新ozon分析数据的最后时间
    setUpdateDateLongTimeFun = () => {
        let _this = this;
        const { datePickerValue } = this.state;
        console.log("datePickerValue===>", datePickerValue)
        if (!datePickerValue) {
            message.error("请选择时间！")
            return
        }

        setUpdateDateLongTime({ "updateDateLong": datePickerValue }).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
                _this.getUpdateDateLongTimeFun() //获取
            } else {
                message.error("更新失败！")
            }
        })

    }

    //更新ozon 的cookie
    refreshCookieFun = (data) => {
        var all_cookie_list = []
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                var data_item = data[key]
                data_item = data_item.trim()
                //console.log(key, data_item);
                if (data_item) {
                    all_cookie_list.push(data_item)
                }
            }
        }
        console.log("all_cookie_list===>", all_cookie_list.length)
        setCookieList({ "stringList": all_cookie_list }).then(res => {
            if (res && res.data && res.data.success) {
                var resData = res.data.data
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })
    }

    //获取ozon cookie
    getCookieListFun = () => {
        let _this = this;
        getCookieList({}).then(res => {
            if (res && res.data) {
                _this.setState({
                    cookieList: res.data.data
                })
            } else {
                _this.setState({
                    cookieList: [],
                })
            }
        });
    }


    //获取版本号
    getVersionFun = () => {
        let _this = this;
        getVersion({}).then(res => {
            if (res && res.data) {
                _this.setState({
                    version: res.data
                })
            } else {
                _this.setState({
                    version: null,
                })
            }
        });
    }

    //更新版本
    resetVersion = () => {
        let _this = this;
        const { version, new_version } = this.state;
        if (!new_version || new_version == version) {
            message.error("请重新输入版本号！")
            return
        }

        setVersion({ "version": new_version }).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
                _this.getVersionFun() //获取版本号
            } else {
                message.error("更新失败！")
            }
        })

    }

    //设置调试
    setDebugFun = (attr1) => {
        let _this = this;
        const { phone } = this.state;
        if (!phone) {
            message.error("请重新输入手机号！")
            return
        }

        setDebug({ "phone": phone, "attr1": attr1 }).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })

    }

    //更新虾皮的所有属性
    getAllCategoryFun = () => {

        getAllCategory({}).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })
    }

    //更新ozon的所有属性
    ozonFreshAllCategoryFun = () => {

        ozonFreshAllCategory({}).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })
    }
    //更新tiktok的所有属性
    refreshAllTiktokTypeFun = () => {

        refreshAllTiktokType({}).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })
    }
    //获取ozon分析数据
    getAllOzonAnalysisDataFun = (id) => {

        getAllOzonAnalysisData({ id }).then(res => {
            if (res && res.data) {
                message.success("更新成功！")
            } else {
                message.error("更新失败！")
            }
        })
    }

    //ozon分析数据的有效时间
    datePickerFun = (date, dateString) => {
        //date.second(0).millisecond(0).minute(0).hour(0).format('YYYY-MM-DDTHH:mm:ss')
        console.log(date.second(0).millisecond(0).minute(0).hour(0).format('YYYY-MM-DD HH:mm:ss'))
        var datePickerValue = date.second(0).millisecond(0).minute(0).hour(0).valueOf()
        console.log("datePickerValue===>", datePickerValue)
        this.setState({
            datePickerValue: datePickerValue
        })
    }


    render() {
        const { version, updateDateLongTime, cookieList } = this.state;


        return (
            <React.Fragment>
                <div className=" ">
                    <Divider>版本设置</Divider>
                    <div className="settingDivMargin">当前版本： {version}</div>
                    <div className="settingDivMargin">更新版本：
                        <InputNumber size="small" min={1} max={20000} defaultValue={10000} onChange={(value) => this.setStateKeyValue("new_version", value)} style={{ width: "180px" }} />
                        <Button type="primary" size="small" onClick={this.resetVersion} style={{ marginLeft: "10px" }} >
                            更新
                        </Button>
                    </div>


                    <Divider>设置调试</Divider>
                    <div className="settingDivMargin">手机号码：<InputNumber size="small" onChange={(value) => this.setStateKeyValue("phone", value)} style={{ width: "180px" }} /></div>
                    <div className="settingDivMargin">打开/关闭：
                        <Button type="primary" size="small" onClick={() => this.setDebugFun("1")} style={{ marginLeft: "10px" }} >打开</Button>
                        <Button type="primary" size="small" onClick={() => this.setDebugFun("0")} style={{ marginLeft: "10px" }} >关闭</Button>
                    </div>

                    <Divider>更新电商平台的类型和属性</Divider>
                    <div className="settingDivMargin">
                        <Button type="primary" size="small" onClick={() => this.getAllCategoryFun()} style={{ marginLeft: "10px" }} >更新虾皮的所有类型属性</Button>
                        <Button type="primary" size="small" onClick={() => this.ozonFreshAllCategoryFun()} style={{ marginLeft: "10px" }} >更新Ozon的所有类型属性</Button>
                        <Button type="primary" size="small" onClick={() => this.refreshAllTiktokTypeFun()} style={{ marginLeft: "10px" }} >更新Tiktok的所有类型属性</Button>
                    </div>
                    <Divider>下载ozon分析数据</Divider>
                    <div className="settingDivMargin">
                        <div className="settingDivMargin">
                            <DatePicker onChange={this.datePickerFun} defaultValue={updateDateLongTime ? dayjs(updateDateLongTime) : null} key={updateDateLongTime} />
                            <Button type="primary" size="small" onClick={this.setUpdateDateLongTimeFun} style={{ marginLeft: "10px" }} >设置最新数据时间</Button>
                        </div>
                        <div className="settingDivMargin">

                            <Form
                                name="更新Ozon Cookie"
                                labelCol={{ span: 5 }}
                                onFinish={this.refreshCookieFun}
                                autoComplete="off"
                                layout="horizontal"
                                style={{ maxWidth: 600, }}
                                size="small"
                                key={cookieList}
                            >
                                {
                                    Array.from({ length: 8 }, (_, index) => index).map(i => {
                                        var t = cookieList[i]
                                        return <Form.Item name={"cookie-" + i} label={"cookie-" + i} colon={false} initialValue={t} style={{ marginBottom: "5px" }}>
                                            <Input defaultValue={t} className="login_input" placeholder="输入cookie" allowClear />
                                        </Form.Item>
                                    })
                                }

                                <Form.Item className="textAlignCenter">
                                    <Button type="primary" htmlType="submit" >更新Ozon Cookie</Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <span>下载前需更新cookie</span>
                        <Button type="primary" size="small" onClick={() => this.getAllOzonAnalysisDataFun(1)} style={{ marginLeft: "10px" }} >下载ozon分析数据(补充下载)</Button>
                        <Button type="primary" size="small" onClick={() => this.getAllOzonAnalysisDataFun(2)} style={{ marginLeft: "10px" }} >下载ozon分析数据(全量下载)</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Setting;