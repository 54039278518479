import React from "react";
import { businessGetList, changeStatus } from "../../http/api.js";
import CommonUtils from '../../http/Common.js';
import {
    Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space, Select, Avatar,
    message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, notification,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "../SearchItem.js";
import MyTable from "../components/MyTable.js";
import dayjs from 'dayjs';


class OtherBusinessManagerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            showModel_init: false,
            tableData: [],
            tableData_total: 0,
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部

        this.businessGetListFun({})//获取第三方服务

    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    //this.openNotification("您还未登录，只能查看部分数据。建议您登录，可查看更多数据。")
    //弹窗右上角提示框
    openNotification = (showText) => {
        notification.info({
            message: '提示',
            description: showText,
            style: { top: "50px" },
            duration: 8,//默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
            showProgress: true,//显示自动关闭通知框的进度条
        });
    };

     //表格分页点击事件
     tablePageChangeFun = (current, pageSize) => {
        var paramsParams = this.state.paramsParams
        paramsParams["current"] = current
        paramsParams["size"] = pageSize
        this.businessGetListFun(paramsParams) // 
    }

    //获取第三方服务
    businessGetListFun = (params) => {
        let _this = this;
        // console.log("params=====>", params)
        if (!params) {
            params = {}
        }
        if (params && params.keyword) {
            params["userId"] = params.keyword;
        }
        params["desc"] = true;
        //缓存参数，分页时候使用
        this.setState({
            "paramsParams": params,
        })

        businessGetList(params).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({
                    tableData: resData.records,
                    tableData_total:resData.total
                })
            } else {
                message.info("操作失败，请重试！")
            }
        })
    }

    //修改发布状态
    changeStatusFun = (id, status) => {
        let _this = this;
        // console.log("params=====>", params)
        changeStatus({ id, status }).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.openNotification("修改成功!")
                _this.tablePageChangeFun(1,10)//获取第三方服务
            } else {
                message.info("操作失败，请重试！")
            }
        })
    }

    render() {
        const { spinning, showModel_init, tableData,tableData_total } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()


        var columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                // render: (text) => <a>{text}</a>,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: 'logo',
                dataIndex: 'logoUrl',
                key: 'logoUrl',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => {
                    var imgUrl = "https://baodanerp.cn/erpData/" + text.replace("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/","")
                    return <Image height={32} src={imgUrl} />
                }
            },
            {
                title: '服务名称',
                dataIndex: 'serviceName',
                key: 'serviceName',
                ellipsis: true,
            },
            {
                title: '用户ID',
                dataIndex: 'userId',
                key: 'userId',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => {
                    if (text == 1) {
                        return <span>货代</span>
                    } else if (text == 2) {
                        return <span>财税</span>
                    } else if (text == 3) {
                        return <span>商标</span>
                    }
                },
            },
            {
                title: '服务平台',
                dataIndex: 'platform',
                key: 'platform',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '服务城市',
                dataIndex: 'city',
                key: 'city',
                ellipsis: true,
            },
            {
                title: '公司名称',
                dataIndex: 'companyName',
                key: 'companyName',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '公司地址',
                dataIndex: 'companyAddress',
                key: 'companyAddress',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '法人',
                dataIndex: 'personName',
                key: 'personName',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '电话',
                dataIndex: 'personPhone',
                key: 'personPhone',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '简介',
                dataIndex: 'info',
                key: 'info',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '认证',
                dataIndex: 'authentication',
                key: 'authentication',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => {
                    if (text == 1) {
                        return <span>已认证</span>
                    } else {
                        return <span>未认证</span>
                    }
                },
            },
            {
                title: '排序',
                dataIndex: 'sort',
                key: 'sort',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '均价',
                dataIndex: 'averagePrice',
                key: 'averagePrice',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '网址',
                dataIndex: 'url',
                key: 'url',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => <a href={text} target="_blank" className="textDecoration ">{text}</a>
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => {
                    //0：申请；1：发布；-1：下线
                    if (text == 0) {
                        return <Tag color="#1677ff">申请中</Tag>
                    } else if (text == 1) {
                        return <Tag color="#52c41a">已发布</Tag>
                    } else if (text == -1) {
                        return <Tag color="#f50">已下架</Tag>
                    }
                }
            },
            {
                title: '申请时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '操作',
                key: 'action',
                width: "120px",//只有文字才有用
                render: (_, record) => {
                    // console.log(record)
                    return <Space size="small">
                        {/* // 1： 超级管理员  2：普通管理员  else： 客户 */}
                        {/* type;//状态  0-草稿  1-审核中  2-已经发布 */}
                        <a className="colCardActionBtn" onClick={() => window.open(window.location.origin + "/businessDetail?id=" + record.id, "_blank")}>预览</a>
                        <a className="colCardActionBtn" onClick={() => this.changeStatusFun(record.id, 1)}>发布</a>
                        <a className="colCardActionBtn" onClick={() => this.changeStatusFun(record.id, -1)}>下架</a>
                    </Space>
                },
            },
        ];

        const searchItems = [
            {
                "title": "服务类型",
                "key": "type",
                "type": "select",
                "defaultValue": true,//是否默认第一个值，将设置到state中
                "options": [
                    { label: "全部", value: "全部" },
                    { label: "货代", value: 1 },
                    { label: "财税", value: 2 },
                    { label: "商标", value: 3 },
                ]
            },
            {
                "title": "状态",
                "key": "status",
                "type": "select",
                "options": [//0：申请；1：发布；-1：下线
                    { label: "全部", value: "全部" },
                    { label: "申请中", value: 0 },
                    { label: "已发布", value: 1 },
                    { label: "已下架", value: -1 },
                ]
            },
            {
                "title": "关键词",
                "key": "keyword",
                "type": "input",
                "placeholder": "用户ID",
            },
        ]

        return (
            <React.Fragment>
                {/* <div style={{ margin: "10px 0px" }}>
                    <Button type="primary" style={{ margin: "0px 10px" }} onClick={() => this.setStateKeyValue("showModel_init", true)}>授权入驻</Button>
                </div> */}
                <SearchItem searchFun={this.businessGetListFun} searchItems={searchItems} />
                <Spin tip="查询中..." spinning={spinning}>
                    <MyTable tableName="OtherBusinessManagerPage"
                        showKeyList={["id", "userId", "type", "platform","serviceName", "city", "companyName", "averagePrice", "action"]}
                        allColumns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"}
                        pagination={{
                            position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
                            total: tableData_total, showTotal: (total) => `共 ${total} 条`,
                            onChange: this.tablePageChangeFun
                        }}
                        key={tableData}
                    />
                </Spin>

                <Modal title="授权入驻" open={showModel_init} footer={null} onCancel={() => this.setStateKeyValue("showModel_init", false)}>
                    <Form
                        name="授权入驻"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        onFinish={this.businessInitFun}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="用户ID"
                            name="userId"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户ID!',
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>

                        <Form.Item label="服务类型" name="type">
                            <Select>
                                <Select.Option value={1}>货代</Select.Option>
                                <Select.Option value={2}>财税</Select.Option>
                                <Select.Option value={3}>商标</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                添加授权
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(OtherBusinessManagerPage);