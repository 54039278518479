import React from "react";
import { getUserList, updateUserInfo, businessInit, } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import { Modal, Input, Button, Select, Space, Form, Tooltip, Image, Table, message, Spin } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';

//用户列表界面

class UserManagerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableData_total: 0,
            payIdArray: [],
            params: {},
            spinning: false,//是否为加载中状态
            showModel_updateUserInfo: false,
            showModelData: null,
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部

        this.getUserListFun({});//获取审核列表
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //查询_分页
    getUserListFun_Page = (current, pageSize) => {
        var paramsParams = this.state.paramsParams
        paramsParams["current"] = current
        paramsParams["size"] = pageSize
        this.getUserListFun(paramsParams) //查询代理人拆分的代金券
    }

    //获取列表
    getUserListFun = (params) => {
        let _this = this;
        //console.log("params==>", params)

        if (params.otherKey) {
            if (params.otherKey.length == 11) {
                params["phone"] = params.otherKey
            } else {
                params["userId"] = params.otherKey
            }
        }

        this.setState({
            paramsParams: params,
            spinning: true,
        }) //缓存参数，用于修改之后刷新

        // params["createdTime"] = params.startTime
        // params["lastUpdateTime"] = params.endTime
        getUserList(params).then(res => {
            _this.setState({ spinning: false })
            if (res && res.data && res.data.success) {
                let resData = res.data.data;

                resData.records.map((t, i) => {
                    t['index'] = (resData.current - 1) * resData.size + i + 1
                })
                _this.setState({
                    tableData: resData.records,
                    tableData_total: resData.total,
                })
            } else {
                _this.setState({
                    tableData: [],
                    tableData_total: 0,
                })
            }
        }).catch(function (error) {//请求失败时
            console.log(error)
            message.info("操作失败，请重试！")
        })
    }


    updateUserInfoFun = (params) =>{
        let _this = this;
        console.log(params)
        
        updateUserInfo(params).then(res=>{
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({showModel_updateUserInfo:false})
                message.info("操作成功！")
               _this.getUserListFun_Page(0,10)
            } else {
                message.info("操作失败，请重试！")
            }
        })
    }

   


    render() {
        const { tableData, tableData_total, spinning, showModel_updateUserInfo, showModelData } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        const searchItems = [
            // {
            //     "title": "状态类型", //状态类型   1-审核中  2-待转账  3-已提现
            //     "key": "buyTime",
            //     "type": "select",
            //     "defaultValue": true,//默认第一个值
            //     "options": [
            //         { label: "审核中", value: 1 },
            //         { label: "已审核", value: 2 },
            //         // { label: "已提现", value: 3 },
            //         { label: "已退款", value: 4 },
            //         { label: "全部", value: "" },
            //     ]
            // },
            {
                "title": "注册时间",
                "key": "rangeTime",
                "type": "rangeTime",
            },

            {
                "title": "ID/手机",
                "key": "otherKey",
                "type": "input",
                "placeholder": "用户ID/手机号码",
            },
        ]

        var columns_user = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                width: 45,
            },
            {
                title: 'ID',
                dataIndex: 'userId',
                key: 'userId',
                width: 60,
            },
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
                // width: 100,
            },
            {
                title: '手机',
                dataIndex: 'phone',
                key: 'phone',
                // width: 100,
            },
            {
                title: '类型',
                dataIndex: 'memberType',
                key: 'memberType',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '微信',
                dataIndex: 'openid',
                key: 'openid',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                ellipsis: true,
                render: (text, record, index) => {
                    if (!text) {
                        return <></>
                    }
                    return <>
                        <Tooltip title={"复制"} placement="top">
                            <CopyOutlined onClick={() => CommonUtils.copyData(text)} />
                        </Tooltip>
                        <Tooltip title={text} placement="top">
                            <span>{text}</span>
                        </Tooltip>
                    </>

                }
            },
            {
                title: '头像',
                dataIndex: 'attr2',
                key: 'attr2',
                ellipsis: true,
                render: (text, record, index) => {
                    if (text) {
                        return <Image width={30} src={text}></Image>
                    } else {
                        return <span>无</span>
                    }
                },
            },
            {
                title: '公司',
                dataIndex: 'company',
                key: 'company',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text, record, index) => {
                    if (!text) {
                        return <></>
                    }
                    return <>
                        <Tooltip title={"复制"} placement="top">
                            <CopyOutlined onClick={() => CommonUtils.copyData(text)} />
                        </Tooltip>
                        <Tooltip title={text} placement="top">
                            <span>{text}</span>
                        </Tooltip>
                    </>

                }
            },
            {
                title: '介绍人',
                dataIndex: 'recommend',
                key: 'recommend',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (status) => {
                    if (status) {
                        return <span>正常</span>
                    } else {
                        return <span>停用</span>
                    }
                },
            },
            {
                title: '注册时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '操作',
                key: 'action',
                width: "48px",//只有文字才有用
                render: (_, record) => (
                    <Space size="middle">
                        <span className="colCardActionBtn" onClick={() => {
                            this.setState({
                                showModel_updateUserInfo: true,
                                showModelData: record
                            })
                        }}>修改</span>
                    </Space>
                ),
            },
        ];




        return (
            <React.Fragment>
                <SearchItem searchFun={this.getUserListFun} searchItems={searchItems} />
                <Spin tip="查询中..." spinning={spinning}>
                    <Table columns={columns_user} dataSource={tableData} size={isPhone ? "small" : "middle"}
                        pagination={{
                            position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 300, 500, 1000, 10000, 100000],
                            defaultPageSize: 10,
                            total: tableData_total,
                            showTotal: (total) => `共 ${total} 条`,
                            onChange: this.getUserListFun_Page
                        }}
                    />
                </Spin>

                <Modal title="修改用户信息" open={showModel_updateUserInfo} footer={null} onCancel={() => this.setStateKeyValue("showModel_updateUserInfo", false)}>
                    {
                        showModelData ?
                            <Form labelCol={{ span: 4, }}
                                wrapperCol={{ span: 20, }}
                                style={{ maxWidth: 1200, }}
                                autoComplete="off"
                                key={showModelData.userId}
                                onFinish={this.updateUserInfoFun}
                            >
                                <Form.Item label="userId" name="userId" initialValue={showModelData ? showModelData.userId : null} >
                                    <span>{showModelData ? showModelData.userId : null}</span>
                                </Form.Item>
                                <Form.Item label="公司" name="company" initialValue={showModelData ? showModelData.company : null}>
                                    <Input defaultValue={showModelData ? showModelData.company : null} />
                                </Form.Item>
                                <Form.Item label="手机号码" name="phone" initialValue={showModelData ? showModelData.phone : null}>
                                    <Input defaultValue={showModelData ? showModelData.phone : null} />
                                </Form.Item>
                                <Form.Item label="省份" name="province" initialValue={showModelData ? showModelData.province : null}>
                                    <Input defaultValue={showModelData ? showModelData.province : null} />
                                </Form.Item>
                                <Form.Item label="城市" name="city" initialValue={showModelData ? showModelData.city : null}>
                                    <Input defaultValue={showModelData ? showModelData.city : null} />
                                </Form.Item>
                                <Form.Item label="api权限" name="attr3" initialValue={showModelData ? showModelData.attr3 : null}>
                                    <Select defaultValue={showModelData ? showModelData.attr3 : null}>
                                        <Select.Option value={"1"}>有权限</Select.Option>
                                        <Select.Option value={""}>无权限</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="状态" name="status" initialValue={showModelData ? showModelData.status : null}>
                                    <Select defaultValue={showModelData ? showModelData.status : null}>
                                        <Select.Option value={1}>正常</Select.Option>
                                        <Select.Option value={0}>停用</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
                                    <Button type="primary" htmlType="submit">修改</Button>
                                </Form.Item>
                            </Form>
                            : null
                    }

                </Modal>

            </React.Fragment>
        );
    }
}

export default UserManagerPage;