import React from "react";
import { businessGetList } from "../../http/api.js";
import CommonUtils from '../../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Pagination, Avatar, Empty,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, notification,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "../SearchItem.js";
import dayjs from 'dayjs';
import MyImageBanner from "../components/MyImageBanner.js";

var businessType = CommonUtils.GetQueryString("type")
class OtherBusinessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessType: businessType ? businessType : 1,
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      tableData: [],
      tableData_total: 0,
      checkboxData_platform: ["全部", 'SHOPEE', 'TIKTOK', 'OZON', 'LAZADA', 'AMAZON', 'WISH', 'LINIO', 'JUMIA', 'VOVA', 'Aliexpress', 'FNAC', 'jdth', 'jdid', 'ebay', 'joom', '韩国乐天', '美客多', 'Shopify', 'Tokopedia', 'CDISCOUNT', 'WOOCOMMERCE', 'Tiki', 'TOPHATTER', 'SHOPLINE', '露天', 'Coupang', '11street', 'Gmarket', 'Auction Co.', 'WeMakePrice', 'Interpark', 'SSG.COM', 'TMON', 'GS SHOP', 'UNIT808', '阿里国际', 'Voghion', 'Allegro', 'DHGATE', 'MAGENTO', 'WALMART', 'UEESHOP', 'SHOPYY', 'JOYBUY', 'JD.ID', 'KILIMALL', 'GEARBEST', 'SHOPLAZZA', 'MERCADO', 'XSHOPPY', 'BIGCOMMERCE', 'SHOPBASE', 'DARAZ', 'ETSY', 'KAUFLAND.DE', 'FUNPINPIN', 'ALLVALUE', 'FANNO', 'SHOPEXP', 'PAYPAL', '天猫淘宝海外', '库存订单', '头程集运'],
      checkBoxValue_platform: [],

      checkboxData_city: [],
      checkBoxValue_city: [],

    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部
    const { businessType } = this.state;
    this.setState({
      checkboxData_city: this.getCheckboxData_city(businessType),
    }, () => this.businessGetListFun({}))

  }

  componentDidUpdate() {
    const { businessType } = this.state;
    var type = CommonUtils.GetQueryString("type")
    if (type && type != businessType) {
      this.setState({
        businessType: type,
        checkboxData_city: this.getCheckboxData_city(type),
        checkBoxValue_platform: [],
        checkBoxValue_city: [],
      }, () => this.businessGetListFun({}))
    }
  }

  //获取服务城市选择数据
  getCheckboxData_city = (type) => {
    var checkboxData_base = ['深圳', '义乌', '东莞', '广州', '上海', '泉州', '威海', '香港', '昆明', '长沙', '武汉', '南宁', '西安', '青岛', '大连', '厦门', '烟台', '连云港', '防城港']
    if (type == 1) {
      checkboxData_base.unshift("全部")  //向前面添加元素
    } else {
      checkboxData_base.unshift("全国")  //向前面添加元素
      checkboxData_base.unshift("全部")  //向前面添加元素
    }
    return checkboxData_base
  }

  setStateKeyValue = (key, value) => {
    console.log("key, value===>", key, value)
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  //this.openNotification("您还未登录，只能查看部分数据。建议您登录，可查看更多数据。")
  //弹窗右上角提示框
  openNotification = (showText) => {
    notification.info({
      message: '提示',
      description: showText,
      style: { top: "50px" },
      duration: 8,//默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
      showProgress: true,//显示自动关闭通知框的进度条
    });
  };

  //分页点击事件
  onShowSizeChange = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.businessGetListFun(paramsParams) // 
  }

  //获取第三方服务
  businessGetListFun = (params) => {
    let _this = this;
    const { checkBoxValue_city, checkBoxValue_platform, businessType } = this.state;
    // console.log("params=====>", params)
    if (!params) {
      params = {}
    }
    var city = checkBoxValue_city.join(',');
    var platform = checkBoxValue_platform.join(',');

    params["type"] = businessType
    params["status"] = 1
    params["platform"] = platform
    params["city"] = city

    var lastRequestTime = new Date().getTime()
    //缓存参数，分页时候使用
    this.setState({
      "paramsParams": params,
      spinning: true,
      lastRequestTime: lastRequestTime
    })

    businessGetList(params).then(res => {
      if (res && res.data && res.data.success) {
        if (lastRequestTime == _this.state.lastRequestTime) {//渲染，认准最后请求的一次。因为"全部"查询5秒，后面的可能先返回。会错乱
          let resData = res.data.data;
          _this.setState({
            tableData: resData.records,
            tableData_total: resData.total
          })
        }
      } else {
        _this.setState({
          tableData: [],
          tableData_total: 0
        })
        message.info("操作失败，请重试！")
      }
    })
  }

  //多选框改变事件
  checkboxChangeFun = (checkedValue) => {
    const { checkboxData_platform, checkBoxValue_platform } = this.state;
    // console.log("checkedValue===>", checkBoxValue_platform.length ,checkboxData_platform.length)
    //全部
    // if (checkedValue.includes(undefined)) {
    //   return
    // }
    if (checkedValue.includes("全部") && checkBoxValue_platform.length != checkboxData_platform.length) {//全部
      checkedValue = checkboxData_platform
    } else if (!checkedValue.includes("全部") && checkBoxValue_platform.includes("全部") && checkedValue.length < checkboxData_platform.length) {//取消全部
      checkedValue = []
    } else if (checkedValue.includes("全部") && checkBoxValue_platform.includes("全部") && checkedValue.length < checkboxData_platform.length) {//全选后，取消单个
      var index = checkedValue.indexOf("全部");
      if (index !== -1) {
        checkedValue.splice(index, 1);
      }
      checkedValue = checkedValue
      // console.log("全选后，取消单个")
    }

    this.setState({
      checkBoxValue_platform: checkedValue
    }, () => {
      this.businessGetListFun({})
    })

  }

  //多选框改变事件
  checkboxChangeFun_city = (checkedValue) => {
    const { checkboxData_city, checkBoxValue_city, checkBoxValue_platform } = this.state;
    // console.log("checkedValue===>", checkBoxValue_city.length ,checkboxData_city.length)
    //全部

    if (checkedValue.includes("全部") && checkBoxValue_city.length != checkboxData_city.length) {//全部
      checkedValue = checkboxData_city
    } else if (!checkedValue.includes("全部") && checkBoxValue_city.includes("全部") && checkedValue.length < checkboxData_city.length) {//取消全部
      checkedValue = []
    } else if (checkedValue.includes("全部") && checkBoxValue_city.includes("全部") && checkedValue.length < checkboxData_city.length) {//全选后，取消单个
      var index = checkedValue.indexOf("全部");
      if (index !== -1) {
        checkedValue.splice(index, 1);
      }
      checkedValue = checkedValue
      // console.log("全选后，取消单个")
    }

    this.setState({
      checkBoxValue_city: checkedValue
    }, () => {
      this.businessGetListFun({})
    })
  }


  render() {
    const { spinning, showModel_, tableData, checkboxData_platform, checkBoxValue_platform, checkboxData_city, checkBoxValue_city, tableData_total,
      paramsParams, businessType
    } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    const bannerData = [
      {
        "img": "/img/banner/banner_otherBusiness.png",
        // "btn1": { "text": "直接购买", "url": window.location.origin + "/price" }
      },
    ]
    const bannerData_phone = [
      {
        "img": "/img/banner/banner_otherBusiness_phone.png",
        // "btn1": { "text": "直接购买", "url": window.location.origin + "/price" }
      },
    ]

    {/* 服务类型，1：货代；2：财税；3：商标 */ }
    var businessName = "货代"
    if (businessType == 2) {
      businessName = "财税"
    } else if (businessType == 3) {
      businessName = "商标"
    }

    // console.log("====>",businessName)

    return (
      <>
        <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />
        <div id="OtherBusinessPage" className="bodyBox">
          <Row justify={"center"} align={"middle"} wrap gutter={[26, 26]} style={{ background: "#FFFFFF", padding: "30px 20px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div style={{ fontSize: "25px", marginBottom: "-20px" }} key={businessName}>{businessName}</div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Flex align="flex-start" justify="flex-start" >
                <div className="forwarderSelectTitle">服务平台：</div>
                <Checkbox.Group value={checkBoxValue_platform} style={{ width: '100%', }} onChange={this.checkboxChangeFun} >
                  <Typography.Paragraph ellipsis={{ rows: 1, expandable: 'collapsible', symbol: (open) => open ? <span>收起</span> : <span>查看全部</span> }} style={{ margin: "0px" }}>
                    {
                      checkboxData_platform.map(i => {
                        return <Checkbox value={i}>{i}</Checkbox>
                      })
                    }
                  </Typography.Paragraph>
                </Checkbox.Group>
              </Flex>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: "-20px" }}>
              <Flex align="flex-start" justify="flex-start" >
                <div className="forwarderSelectTitle">服务城市：</div>
                <Checkbox.Group value={checkBoxValue_city} onChange={this.checkboxChangeFun_city} style={{ width: '100%', }} >
                  <Typography.Paragraph ellipsis={{ rows: 1, expandable: 'collapsible', symbol: (open) => open ? <span>收起</span> : <span>查看全部</span> }} style={{ margin: "0px" }}>
                    {
                      checkboxData_city.map(i => {
                        return <Checkbox value={i}>{i}</Checkbox>
                      })
                    }
                  </Typography.Paragraph>
                </Checkbox.Group>
              </Flex>
            </Col>

            {
              tableData && tableData.length > 0 ?
                tableData.map(item => {
                  return <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                    <div className="otherItemBox">
                      <Flex gap="middle" align="center" justify="start" >
                        <div className="">
                          <Avatar size={64} src={"https://baodanerp.cn/erpData/" + item.logoUrl.replace("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/", "")} />
                        </div>
                        <div className="serviceListTitle">
                          {item.serviceName}
                        </div>
                      </Flex>

                      <div style={{ margin: "10px 0px" }}>
                        <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, }} style={{ margin: "0px" }}>
                          {
                            item.platform.split(",").map(i => {
                              return <Tag color="blue" style={{ margin: "3px", background: "#FFF" }}>{i}</Tag>
                            })
                          }
                        </Typography.Paragraph>
                      </div>

                      <div style={{ margin: "10px 0px", background: "#f7f8f8", padding: "5px 10px", fontSize: "12px", borderRadius: "4px" }}>

                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: false, }} style={{ color: "#666666", margin: "0px" }}>
                          {"服务简介:  " + item.info}
                        </Typography.Paragraph>
                      </div>

                      <div style={{ margin: "10px 0px" }} id="cityBox">
                        <Flex gap="middle" align="center" justify="start" >
                          <div style={{ minWidth: "38px" }}>
                            城市:
                          </div>
                          <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, }} style={{ margin: "0px" }}>
                            {
                              item.city.split(",").map(i => {
                                return <Tag color="blue" style={{ margin: "3px", background: "#FFF" }}>{i}</Tag>
                              })
                            }
                          </Typography.Paragraph>
                        </Flex>
                      </div>

                      <div style={{ margin: "10px 0px" }} id="cityBox">
                        <Flex gap="middle" align="center" justify="start" >
                          <div style={{ minWidth: "38px" }}>
                            均价:
                          </div>
                          <span>{item.averagePrice}元</span>
                        </Flex>
                      </div>

                      <Row justify={"center"} align={"middle"} wrap gutter={[16, 16]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Button style={{ width: "100%", borderRadius: "0px" }} onClick={() => window.open(window.location.origin + "/businessDetail?id=" + item.id, "_blank")}>查看详情</Button>
                        </Col>
                        {item.url && item.url != "undefined" && item.url != "无"?
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Button style={{ width: "100%", borderRadius: "0px" }} onClick={() => window.open(item.url, "_blank")}>打开官网</Button>
                          </Col>
                          : null}

                      </Row>



                    </div>
                  </Col>
                })
                : 
                // <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <Empty width="20" image={"/img/pro/no_data.png"} />
            }
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Pagination showSizeChanger onChange={this.onShowSizeChange}
                total={tableData_total}
                showTotal={(total, range) => `共 ${total} 条`}
                align="center"
                key={JSON.stringify(checkBoxValue_platform + checkBoxValue_city)}
              />
            </Col>
          </Row>


          <Modal title="添加xpath" open={showModel_} footer={null} onCancel={() => this.setStateKeyValue("showModel_", false)}>
            <Form labelCol={{ span: 5, }}
              wrapperCol={{ span: 19, }}
              style={{ maxWidth: 600, }}
              onFinish={this.addAuthShopFun}
              autoComplete="off"
            >
              <Form.Item label="店铺名称" name="shop_name"
                rules={[{
                  required: true,
                  message: '请输入店铺名称!',
                },]} >
                <Input placeholder="如：店铺1-ozon" />
              </Form.Item>

              <Form.Item label="Client ID" name="shopId"
                rules={[{
                  required: true,
                  message: '请输入店铺名称!',
                },]} >
                <Input type="number" />
              </Form.Item>
              <Form.Item label="API 密钥" name="accessToken"
                rules={[{
                  required: true,
                  message: '请输入店铺名称!',
                },]} >
                {/* <Input.Password /> */}
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
                <Button type="primary" htmlType="submit">
                  添加授权
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}

export default withRouter(OtherBusinessPage);