import React from "react";
import {
    Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space, Dropdown,
    message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, notification,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined, DownOutlined, SmileOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import { wechatJsSign } from "../http/api";
import CommonUtils from '../http/Common';

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        let _this = this;
        // CommonUtils.openLoading("loading")
        CommonUtils.openLoadingImg("loading")

    }




    render() {
        const { } = this.state;


        return (
            <div className="" style={{ "height": "100vh" }}>
                <img src={'img/loading.gif'} width={200} height={200}></img>
                <div id="loading" className="positionRelative" style={{ width: "200px", height: "200px" }}></div>


            </div>
        );
    }
}

export default Test;





