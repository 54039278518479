import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import TextImageCard from './components/TextImageCard';
import FunctionCard from './components/FunctionCard';
// import QueueAnim from 'rc-queue-anim';
import MyImageBanner from "./components/MyImageBanner.js";
import WechatJsCommon from './components/WechatJsCommon';



class Homepage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null,
         isPhone: CommonUtils.isPhoneClient(),
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部
      WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
      
   }


   render() {
      const { isPhone } = this.state;

      const contentTextObj = [
         {
            title: "功能强大，操作简单",
            content: '批量采集商品、上架商品、处理订单，一键搞定！不用频繁安装各种复杂的插件，不用过多的操作,系统智能处理。让您成为市场上的明星卖家。',
            img: "/img/pro/home1.png",
         },
         {
            title: "丰富资料，满足多场景运用",
            content: '批量采集图片，规格，描述文字和描述图片，并添加正品、优惠、包邮的标签,自动合成视频等丰富商品资料。让我们的跨境电商ERP软件助您高效赚取更多的收益！',
            img: "/img/pro/home2.png",
         },

         {
            title: "一键批量处理",
            content: '批量采集商品、上架商品、处理订单，一键搞定！不用频繁安装各种复杂的插件，不用过多的操作,系统智能处理。让您成为市场上的明星卖家。',
            img: "/img/pro/home3.png",
         },

         {
            title: "性价比最高",
            content: '全网性价比最高的跨境电商ERP软件！为您提供最优惠的价格，更强大的功能，让您省时省力省成本！更低的运营成本，竞争力也会更加强劲！',
            img: "/img/pro/home4.png",
         },
      ]

      const contentTextObjProduct= [ {
         title: "批量采集商品",
         content: '批量商品采集、单品采集，解决无货源问题。\n可根据用户重点关注的商品维度进行采集。\n可根据商品类别、商品属性、外观条件等作为条件进行采集。\n可根据商品销量、商品价格、商品好评排序等条件进行采集。\n',
         img: "/img/erp_download_params.jpg",
         preview:true,
      },
      {
         title: "商品批量编辑上架",
         content: '批量编辑商品上架：批量编辑属性、自动翻译、编辑商品标题、批量定价、批量折扣商品、批量加价。\n自动生成商品展示视频，描述AI自动翻译成该站点语言。\n也可单品编辑商品详情、规格、尺寸、重量， 可以选择尺码图片、文本翻译、AI图片翻译、商品描述等。\n一键批量上架，自动生成视频，自动添加标签，效率高。\n标题自动分词，可添加公共商品描述。',
         img: "/img/erp_upload.jpg",
         preview:true,
      },
      {
         title: "线上商品批量管理",
         content: '多店铺线上商品统一管理、方便批量修改，批量管理。\n可统一调整折扣方案、批量下架等。',
         img: "/img/erp_platformGood.jpg",
         preview:true,
      },
      {
         title: "店铺订单批量管理",
         content: "多站点店铺客户订单统一管理，直接去采购。\n可统计同一商品的总数量，方便快速确定采购数量。",
         img: "/img/erp_orderPage.jpg",
         preview:true,
      },
      {
         title: "后台统一设置",
         content: "便捷对接电商平台店铺、统一添加店铺各种标签：如包邮、正品、优惠券等。\n自行选择是否生成商品宣传视频。\n设置发货时间、选择是否要自动翻译站点语言等。",
         img: "/img/erp_autoSetting.jpg",
         preview:true,
      },
      {
         title: "收集需求，不断升级",
         content: "我们将不断的升级软件的功能！\n您在不经意间的一个小想法，都可以提交给我们！\n我们会尽一切可能，帮您实现！",
         img: "/img/erp_feedback.jpg",
         preview:true,
      },
]



      const littleCardData = [
         {
            "title": "商品批量采集",
            "text1": "单品采集",
            "text2": "关键词采集",
            "img": "/img/pro/homeIcon1.png",
         },
         {
            "title": "商品批量上架",
            "text1": "批量编辑上架",
            "text2": "Ai图片翻译",
            "img": "/img/pro/homeIcon2.png",
         },
         {
            "title": "商品管理",
            "text1": "多店铺统一管理",
            "text2": "功能强大,操作简单",
            "img": "/img/pro/homeIcon3.png",
         },
         {
            "title": "订单管理",
            "text1": "分类统计订单",
            "text2": "便捷采购",
            "img": "/img/pro/homeIcon4.png",
         },
      ]

      const bannerData = [
         {
            "img":"/img/banner/banner_home1.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home2.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home3.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home4.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/user/agentChange",className:"bannerItemBtnColor"}
         },
      ]

      const bannerData_phone = [
         {
            "img":"/img/banner/banner_home1_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home2_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home3_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/banner/banner_home4_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/user/agentChange",className:"bannerItemBtnColor"}
         },
      ]



      return (
         <React.Fragment>

            <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />

            {/* <QueueAnim type={"bottom"} key={"queue"} duration={900} ease="easeInOutQuart" > */}

            <div className="rootBox">
               <FunctionCard data={littleCardData}></FunctionCard>
               {
                  contentTextObj.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index}/>)
                  })
               }
               <div className="homeProductInfoTitle">产品简介</div>
               {
                  contentTextObjProduct.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index} />)
                  })
               }
            </div>

            {/* </QueueAnim> */}
         </React.Fragment>
      );
   }
}

export default Homepage;