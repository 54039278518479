import React from "react";
import { distinguishBasic, loginByToken } from "../http/api";
import CommonUtils from '../http/Common';
import { message, Image, Col, Button, Descriptions, Statistic, Card, Row, } from 'antd';
import WechatJsCommon from './components/WechatJsCommon';

const { Countdown } = Statistic;



class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      roleData: null,
    };
  }

  componentDidMount() {
    CommonUtils.goToTop()//回到顶部
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (user_cache && user_cache.loginTime) {
      var loginTime = user_cache.loginTime

      if ((new Date()).getTime() - loginTime < 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
        this.setState({
          user: user_cache
        })
      } else {
        message.info("请登陆！")
      }

    } else {
      message.info("请登陆！")
    }

    var roleData = CommonUtils.getLocalStorage("roleData");//缓存用户信息
    if (roleData) {
      this.setState({
        roleData: roleData,
      })
    }

    //是否刷新一次
    var refresh = CommonUtils.GetQueryString("refresh",true)
    if(refresh){
      this.refreshFun()//使用token刷新数据
    }


  }

  //使用token刷新数据
  refreshFun = () => {
    let _this = this;
    CommonUtils.delQueryString("login");//去除search参数
    CommonUtils.delQueryString("register");//去除search参数
    CommonUtils.delQueryString("reset");//去除search参数
    CommonUtils.delQueryString("token");//去除search参数
    CommonUtils.delQueryString("refresh");//去除search参数

    loginByToken({}).then(res => {
      if (res && res.data && res.data.success) {
        var resData = res.data.data
        resData.user.loginTime = (new Date()).getTime()
        CommonUtils.loginSaveData(resData)
        window.location.reload();//刷新页面
        message.success("刷新成功！")
      } else {
        message.success("刷新失败！请重新登录！")
      }
    })
  }

  logout = () => {
    CommonUtils.setLocalStorage("token", null)
    CommonUtils.setLocalStorage("user", null)
    CommonUtils.setLocalStorage("roleData", null)

    this.setState({
      user: null,
    })
    window.location.pathname = '/home' //返回首页并刷新
    // window.location.reload()

    message.success("退出登陆成功！")
  }


  render() {
    const { user, roleData, } = this.state;
    // console.log("roleData==>", roleData)
    return (
      <React.Fragment>
        <div>
          {/* <Image className="profileHead_img" src="/img/user_banner.png" width={"100%"} preview={false}  ></Image>   className="profile_user_box"*/}

          <div>
            {
              user ?
                <>
                  <div className="profileUserNane">
                    <Row gutter={16} justify={"left"} align={"middle"}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className="profileUserNaneCard" >
                        <div className="headerHeadImgBox headerUserImg">
                          <Image className="user_head" src={user.attr2 ? user.attr2 : "/img/user_head.png"} width={40} height={40} preview={false}></Image>
                          {
                            user && user.memberType != "base" ? <div className="headerVIPBox">VIP</div> : null
                          }
                        </div>
                        <span className="headerUserNameText">{user.phone ? user.phone : user.name}</span>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className="profileUserNaneCard" >
                        <span className="headerUserNameTitle">ERP ID：</span>
                        <span className="headerUserNameText">{user.userId}</span>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className="profileUserNaneCard" >
                        {user && user.attr3 != "1" ?
                          <>
                            <span className="headerUserNameTitle">分成比例：</span>
                            <span className="headerUserNameText">30%</span>
                          </>
                          : <>
                            <span className="headerUserNameTitle">分成比例：</span>
                            <span className="headerUserNameText">无</span>
                          </>}

                      </Col>
                    </Row>
                  </div>

                  <Row gutter={16} justify={"left"} align={"left"}>
                    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                      <Card bordered={false} >
                        <div className="colCardTitle">账号</div>
                        <div className="colCardFooter">账号：{user.name ? user.name : user.phone}</div>
                        <div className="colCardFooter">ERP ID：{user.userId}</div>
                        <div className="colCardFooter">提成比例：30%</div>
                      </Card>
                    </Col> */}
                    {
                      roleData && roleData.shopCommon ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">订购店铺-Shopee</div>
                            <div className="colCardFooter">订购店铺：{roleData.shopCommon.count} 个</div>
                            <div className="colCardFooter">到期时间：{roleData.shopCommon.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.shopCommon.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.shopTiktok ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">订购店铺-Tiktok</div>
                            <div className="colCardFooter">订购店铺：{roleData.shopTiktok.count} 个</div>
                            <div className="colCardFooter">到期时间：{roleData.shopTiktok.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.shopTiktok.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.shopOzon ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">订购店铺-Ozon</div>
                            <div className="colCardFooter">订购店铺：{roleData.shopOzon.count} 个</div>
                            <div className="colCardFooter">到期时间：{roleData.shopOzon.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.shopOzon.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.translateImg ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">图片翻译(通用)</div>
                            <div className="colCardFooter">剩余数量：{roleData.translateImg.count} 张</div>
                            <div className="colCardFooter">到期时间：{roleData.translateImg.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.translateImg.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                     {
                      roleData && roleData.bestTranslateImg ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">图片翻译(高级)</div>
                            <div className="colCardFooter">剩余数量：{roleData.bestTranslateImg.count} 张</div>
                            <div className="colCardFooter">到期时间：{roleData.bestTranslateImg.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.bestTranslateImg.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.baseTranslateText ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">文本翻译(通用)</div>
                            <div className="colCardFooter">剩余数量：{roleData.baseTranslateText.count} 字符</div>
                            <div className="colCardFooter">到期时间：{roleData.baseTranslateText.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.baseTranslateText.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.translateText ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">文本翻译(高级)</div>
                            <div className="colCardFooter">剩余数量：{roleData.translateText.count} 字符</div>
                            <div className="colCardFooter">到期时间：{roleData.translateText.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.translateText.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.fans ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">关注大卖粉丝</div>
                            <div className="colCardFooter">订购店铺：{roleData.fans.count} 个</div>
                            <div className="colCardFooter">到期时间：{roleData.fans.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.fans.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                    {
                      roleData && roleData.statisticsOzon ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">统计选品</div>
                            <div className="colCardFooter">订购套餐：{roleData.statisticsOzon.count == 1 ? "top100"
                              : (roleData.statisticsOzon.count == 2 ? "top500" :
                                (roleData.statisticsOzon.count == 3 ? "top1000" :
                                  (roleData.statisticsOzon.count == 4 ? "不限制" : null)
                                )
                              )
                            }

                            </div>
                            <div className="colCardFooter">到期时间：{roleData.statisticsOzon.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.statisticsOzon.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }

                    {
                      roleData && roleData.chatGPT ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">国产DeepSeek AI服务</div>
                            <div className="colCardFooter">剩余次数：{roleData.chatGPT.count} 次</div>
                            <div className="colCardFooter">到期时间：{roleData.chatGPT.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.chatGPT.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                     {
                      roleData && roleData.baseWhiteBackground ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">图片白底 (通用)</div>
                            <div className="colCardFooter">剩余数量：{roleData.baseWhiteBackground.count} 张</div>
                            <div className="colCardFooter">到期时间：{roleData.baseWhiteBackground.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.baseWhiteBackground.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                     {
                      roleData && roleData.bestWhiteBackground ?
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} className="profileCard" >
                          <Card bordered={false} >
                            <div className="colCardTitle">图片白底 (高级)</div>
                            <div className="colCardFooter">剩余数量：{roleData.bestWhiteBackground.count} 张</div>
                            <div className="colCardFooter">到期时间：{roleData.bestWhiteBackground.expireTime}</div>
                            <div className="colCardFooter">剩余时间：<span className="priceTime"><Countdown value={roleData.bestWhiteBackground.expireTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></div>
                          </Card>
                        </Col>
                        : null
                    }
                   

                  </Row>
                </>

                : null
            }

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;