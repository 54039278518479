import React from "react";
import { searchNews } from "../../http/api";
import CommonUtils from '../../http/Common';
import {
    Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
    message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Tag, Tabs, Avatar, List, Skeleton
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink, withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
const { Paragraph, Text } = Typography;


class NewsScrollLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            showModel_: false,
            tableData: [],
            tableData_tota: 0,
            name: props.name,
            tag: props.tag,
            hot: props.hot,
        };
    }

    componentDidMount() {
        let _this = this;
        this.searchFun()
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    searchFun = (current) => {
        let _this = this;
        const { tag, hot } = this.state;
        var params = {
            "type": 2,
            "current": current,
            //"tag":"" ,//文章标签  1:行业资讯  2:运营技巧   3:基础常识   4:国家政策
            //"hot":1 ,
        }
        if (tag) {
            params["tag"] = tag
        }
        if (hot) {
            params["hot"] = hot
        }

        searchNews(params).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({
                    tableData: _this.state.tableData.concat(resData.records),
                    tableData_total: resData.total,
                })
                // message.success("请求成功！")
            }
            else {
                _this.setState({
                    tableData: [],
                    tableData_total: 0
                })
            }
        }).catch(function (error) {//请求失败时
            message.info("请求失败，请重试！")
        })
    }



    render() {
        const { name, showModel_, tableData, tableData_total } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()


        return (
            <React.Fragment>
                <div id={"scrollableDiv_" + name}
                    className="scrollableList"
                    style={{
                        height: 920,
                        overflow: 'auto',
                        border: 'none',
                    }}>
                    <InfiniteScroll
                        dataLength={tableData.length}
                        next={() => this.searchFun(tableData.length / 10 + 1)}
                        hasMore={tableData.length < tableData_total}
                        endMessage={<Divider plain>已加载完</Divider>}
                        scrollableTarget={"scrollableDiv_" + name}
                    >
                        <List dataSource={tableData} renderItem={(item) => (
                            <List.Item className="cursorPointer" key={item.id} onClick={() => window.open(window.location.origin + "/newsDetail?number=" + item.id, "_blank")}>
                                <div className="newsListTitle" style={{ margin: "5px 0px 13px 0px" }}> {item.title}</div>

                                <div style={{ marginBottom: "8px" }}>
                                    {
                                        item.tag == 1 ? <span className="newsDetailTag">行业资讯</span> : null
                                    }
                                    {
                                        item.tag == 2 ? <span className="newsDetailTag" style={{ background: "#2BBA31B2" }}>运营技巧</span> : null
                                    }
                                    {
                                        item.tag == 3 ? <span className="newsDetailTag" style={{ background: "#04AEF7B2" }}>基础常识</span> : null
                                    }
                                    {
                                        item.tag == 4 ? <span className="newsDetailTag" style={{ background: "#FF5C00B2" }}>国家政策</span> : null
                                    }
                                    <span className="newsDetailTime">{item.timeFormat}</span>
                                </div>

                                <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '更多', }} className="newsDetailContent">
                                    {
                                        JSON.parse(item.content).map(i => {
                                            if (i.type == "text") {
                                                return i.value
                                            }
                                        })
                                    }
                                </Paragraph>


                            </List.Item>
                        )}
                        />
                    </InfiniteScroll>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(NewsScrollLoad);
