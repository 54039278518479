import React from "react";
import { addCashOut, getCashOutList, changeCashOut, transferAccounts, } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import { Spin, Tag, Button, Checkbox, Select, Input, Col, Row, Modal, Table, Space, message, Image, Badge, Dropdown, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined, DownOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import WechatJsCommon from './components/WechatJsCommon.js';
import { saveAs } from 'file-saver';

//提现记录

class CashOutCheckPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIdList: [],
      cashOutList: [],
      cashOutList_total: 0,
      cashMoney: 0,
      showModel_cashout: false,
      spinning: false,
      remarks: null,
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部

    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()
      }, 3000);
    } else {
      _this.initFun()
    }

  }

  //初始化方法
  initFun = () => {
    let _this = this;
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getcashOutListFun()//查询记录
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //批量下载发票
  downFiles = () => {
    let { cashOutList } = this.state
    cashOutList.map(e => {
      if(e.invoice){
        // saveAs("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/" + e.invoice);
        saveAs("https://baodanerp.cn/erpData/" + e.invoice);
      }
    })
  }

  //查询转化记录_分页
  getcashOutListFun_Page = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getcashOutListFun(paramsParams) //查询代理人拆分的代金券
  }

  //查询记录
  getcashOutListFun = (params) => {
    //默认不用时间范围,加上时间范围,查询太慢了(1分钟多).
    let _this = this;
    if (!params) {
      params = {}
    }

    params["userName"] = "cheak"//这个区分条件

    this.setState({
      paramsParams: params,
      spinning: true,
    })


    getCashOutList(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
          t['key'] = t.id
        })


        _this.setState({
          cashOutList: resData.records,
          cashOutList_total: resData.total,
          selectedIdList: [],
        })

      } else {
        _this.setState({
          cashOutList: [],
          selectedIdList: [],
          cashOutList_total: 0,
        })
        message.info("请求失败，请重试！")
      }
    });

  }

  //修改审批状态
  changeTypeFun = (cashStatus) => {
    let _this = this;
    const { selectedIdList, remarks } = this.state;
    if (selectedIdList.length == 0) {
      message.info("请先选择！")
      return
    }

    //状态   1-审核中  2-待转账  3-已提现', 4 作废
    changeCashOut({ "cashStatus": cashStatus, "idArray": selectedIdList, "remarks": remarks }).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.getcashOutListFun(_this.state.paramsParams) //获取列表
      }
    }).catch(function (error) {//请求失败时
      console.log(error)
      message.info("操作失败，请重试！")
    })
  }

  //发起转账
  transferAccountsFun = () => {
    let _this = this;
    const { selectedIdList, } = this.state;
    if (selectedIdList.length == 0) {
      message.info("请先选择！")
      return
    }

    transferAccounts({ "idArray": selectedIdList }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.getcashOutListFun(_this.state.paramsParams) //获取列表
      }
    }).catch(function (error) {//请求失败时
      console.log(error)
      message.info("操作失败，请重试！")
    })
  }


  //表格行点击
  onRowClickFun = (data, index, event) => {
    var clickText = event.target.innerText
    if (data.cashMoney >= 10000 && !["审核通过", "未批准", "设为审核中", "设为已提现", "作废", "操作"].includes(clickText)) {
      // console.log(data,index,clickText)
      this.setState({
        showModel_companyInfo: true,
        companyInfo: data,
      })
    }
  }

  actionClickFun = (index, data) => {
    let _this = this;
    // console.log("index,data == >", index, data)
    this.setState({
      selectedIdList: [data.id]
    }, () => {
      if (index == "-1" || index == '4') {
        _this.setState({
          showModel_remarks: true,
          actionIndex: index,
        })
      } else {
        this.changeTypeFun(index)
      }
    })
  }

  remark_handleOk_fun = () => {
    const { actionIndex } = this.state
    this.setState({
      showModel_remarks: false,
    })

    this.changeTypeFun(actionIndex)
  }




  render() {
    const { cashOutList, cashOutList_total, showModel_cashout, cashMoney, spinning, selectedIdList, showModel_companyInfo, companyInfo, showModel_remarks, actionIndex } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    const searchItems_CashOut = [
      {
        "title": "状态类型", //状态类型  1-审核中  2-待转账  3-已提现',
        "key": "cashStatus",
        "type": "select",
        "defaultValue": true,//默认第一个值
        "tooltipText": "未批准:会将申请额度返回。作废：不会返回申请额度。转账：需要设置为待转账状态，才可以成功发起转账。达到1万的提现金额，不能通过微信转账。当有本页的状态由已经提现改为审核中时，需要点击一下转化审核界面的按钮“修改状态后重新统计”，重新统计审核中的总金额。",
        "options": [
          { label: "全部", value: "" },
          { label: "审核中", value: 1 },
          { label: "待转账", value: 2 },
          { label: "已提现", value: 3 },
          { label: "未批准", value: -1 },
          { label: "作废", value: 4 },

        ]
      },
      {
        "title": "申请时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
      {
        "title": "代理人ID",
        "key": "erpUserId",
        "type": "input",
        "placeholder": "代理人ERP ID",
      },
    ]

    var columns_CashOut = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '账户名称',
        dataIndex: 'userName',
        key: 'userName',
      },
      {
        title: 'ERP ID',
        dataIndex: 'erpUserId',
        key: 'erpUserId',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '提现金额',
        dataIndex: 'cashMoney',
        key: 'cashMoney',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '服务费比率',
        dataIndex: 'serviceMoneyRate',
        key: 'serviceMoneyRate',
        render: (text) => <span>{text * 100}%</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '服务费',
        dataIndex: 'serviceMoney',
        key: 'serviceMoney',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '实际到账',
        dataIndex: 'realityCashMoney',
        key: 'realityCashMoney',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '申请时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '状态',
        dataIndex: 'cashStatus',
        key: 'cashStatus',
        render: (cashStatus, record, index) => {
          if (record.status == 0) {
            return <Badge count={"已作废"} style={{ backgroundColor: '#000', }} />
          }
          //状态   1-审核中  2-待转账  3-已提现', #
          if (cashStatus == 2) {
            return <Badge count={"待转账"} style={{ backgroundColor: '#FF803A', }} />
          } else if (cashStatus == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#d3d3d3', }} />
          } else if (cashStatus == 3) {
            return <Badge count={"已提现"} style={{ backgroundColor: '#52c41a', }} />
          } else if (cashStatus == -1) {
            return <Badge count={"未批准"} style={{ backgroundColor: '#f00', }} />
          }

        },
      },
      {
        title: '转账时间',
        dataIndex: 'cashTime',
        key: 'cashTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '发票',
        dataIndex: 'invoice',
        key: 'invoice',
        render: (text, record, index) => {
          if (text) {
            // return <Image width={30} src={"https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/" + text}></Image>
            return <Image width={30} src={"https://baodanerp.cn/erpData/" + text}></Image>
          } else {
            return <span>无</span>
          }
        },
      },
      {
        title: '备注',
        dataIndex: 'remarks',
        key: 'remarks',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record) => {
          // console.log(record)
          return <Dropdown menu={{
            items: [{ label: '审核通过', key: '2', }, { label: '未批准', key: '-1', }, { label: '设为审核中', key: '1', }, { label: '设为已提现', key: '3', }, { label: '作废', key: '4', }],
            onClick: (item) => { this.actionClickFun(item.key, record); }
          }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                操作
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        },
      },

    ];


    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectedIdList: selectedRowKeys
        })
      },
      selections: [
        {
          key: 'pass',
          text: '审核通过',
          onSelect: (changeableRowKeys) => {
            console.log("审核通过")
            this.changeTypeFun(2) // 修改审批状态 type;//状态类型  1-审核中  2-待转账  3-已提现'

          },
        },
        {
          key: 'reCheck',
          text: '设为审核中',
          onSelect: (changeableRowKeys) => {
            console.log("设为审核中")
            this.changeTypeFun(1) // 修改审批状态 type;//状态类型  1-审核中  2-待转账  3-已提现'
          },
        },
        {
          key: 'cashOut',
          text: '设为已提现',
          onSelect: (changeableRowKeys) => {
            console.log("设为已提现")
            this.changeTypeFun(3) // 修改审批状态 type;//状态类型   1-审核中  2-待转账  3-已提现'
          },
        },
        {
          key: 'transferAccounts',
          text: '发起转账',
          onSelect: (changeableRowKeys) => {
            console.log("发起转账")
            this.transferAccountsFun() // 修改审批状态 type;//状态类型   1-审核中  2-待转账  3-已提现'
          },
        },
        {
          key: 'gitBack',
          text: '作废',
          onSelect: (changeableRowKeys) => {
            console.log("作废")
            this.changeTypeFun(4) // 修改审批状态 type;//状态类型   1-审核中  2-待转账  3-已提现'
          },
        },
      ],
    };

    return (
      <React.Fragment>
        <div className="exchangeCouponHeader" style={isPhone ? { lineHeight: "50px", padding: "15px" } : { lineHeight: "80px" }}>
          <Row justify={"left"} align={"middle"}>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} push={2} >
              <div>
                <div className="exchangeCouponMy cashoutCheckTotal">
                  <Button onClick={this.downFiles}>下载当页所有发票</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <SearchItem searchFun={this.getcashOutListFun} searchItems={searchItems_CashOut} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns_CashOut} dataSource={cashOutList} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 300, 500, 1000, 10000, 100000],
              total: cashOutList_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.getcashOutListFun_Page
            }}
            rowSelection={{ type: "checkbox", ...rowSelection, selectedRowKeys: selectedIdList }}
            summary={(pageData) => {
              let totalMoney_count = 0;
              // console.log("pageData==>",pageData)
              pageData.forEach(({ payablePrice, realityCashMoney }) => {
                totalMoney_count += realityCashMoney;
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell ></Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={2}>本页合计</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
                    {/* <Table.Summary.Cell colSpan={1}>{payablePrice_count}元</Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={1}>{totalMoney_count.toFixed(2)}元</Table.Summary.Cell> */}
                    <Table.Summary.Cell colSpan={7}>实际总支出 {totalMoney_count.toFixed(2)} 元</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
            onRow={(record, index) => {
              return {
                onClick: (event) => this.onRowClickFun(record, index, event), // 点击行
                onDoubleClick: (event) => { },
                onContextMenu: (event) => { },
                onMouseEnter: (event) => { }, // 鼠标移入行
                onMouseLeave: (event) => { },
              };
            }}
          />
        </Spin>


        <Modal title="公司信息" open={showModel_companyInfo} footer={null} onCancel={() => this.setStateKeyValue("showModel_companyInfo", false)}>
          {
            companyInfo ?
              <>
                <div className="companyInfoBox"><span className="companyInfoTitle">序号:</span>{companyInfo.index}</div>
                <div className="companyInfoBox"><span className="companyInfoTitle">账户名称:</span>{companyInfo.userName}</div>
                <div className="companyInfoBox"><span className="companyInfoTitle">公司名称:</span>{companyInfo.companyName}</div>
                <div className="companyInfoBox"><span className="companyInfoTitle">信用代码:</span>{companyInfo.companyCode}</div>
                <div className="companyInfoBox"><span className="companyInfoTitle">对公账户:</span>{companyInfo.companyBank}</div>
                <div className="companyInfoBox"><span className="companyInfoTitle">开户名称:</span>{companyInfo.companyCard}</div>
              </>
              : null
          }
        </Modal>

        <Modal title="添加备注" open={showModel_remarks} onOk={this.remark_handleOk_fun} onCancel={() => this.setStateKeyValue("showModel_remarks", false)}>
          <Input placeholder="填写备注" onChange={(e) => this.setStateKeyValue("remarks", e.target.value)}></Input>
        </Modal>

      </React.Fragment>
    );
  }
}

export default CashOutCheckPage;