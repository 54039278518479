import React from "react";
import { giftGetNewUserList, exchangeGift, exchangeHistory } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, notification, Avatar, Segmented,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';
import MyImageBanner from "./components/MyImageBanner.js";
import ShareBox from '../pages/components/ShareBox';

class SalesPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModal_success: false,
      newUserList: [],
      tableData: [],
      tableData_total: 0,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    // console.log(user_cache)
    if (user_cache && user_cache.loginTime) {
      var loginTime = user_cache.loginTime
      // console.log('loginTime===>', CommonUtils.dateFtt("yyyy-MM-dd hh:mm:ss", new Date(loginTime)))
      if ((new Date()).getTime() - loginTime < 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
        this.setState({
          user: user_cache
        })
      }
    }

    this.giftGetNewUserListFun()//查询最新100条邀请的用户
    _this.exchangeHistoryFun()//查询领取记录

  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  //this.openNotification("您还未登录，只能查看部分数据。建议您登录，可查看更多数据。")
  //弹窗右上角提示框
  openNotification = (showText) => {
    notification.info({
      message: '提示',
      description: showText,
      style: { top: "50px" },
      duration: 8,//默认 4.5 秒后自动关闭，配置为 null 则不自动关闭
      showProgress: true,//显示自动关闭通知框的进度条
    });
  };

  //查询最新100条邀请的用户
  giftGetNewUserListFun = () => {
    let _this = this;

    this.setState({
      spinning: true,
    })

    giftGetNewUserList({}).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        var resData = res.data.data;
        _this.setState({
          newUserList: resData
        })
      } else {
        _this.setState({
          newUserList: []
        })
      }
    })
  }

  //领取礼包
  exchangeGiftFun = (type) => {
    let _this = this;
    const { newUserList } = this.state;
    if (type == "礼包一" && newUserList.length < 10) {
      _this.openNotification("您还需邀请" + (10 - newUserList.length) + "个新用户，即可领取礼包一！")
      return
    }
    if (type == "礼包二" && newUserList.length < 30) {
      _this.openNotification("您还需邀请" + (30 - newUserList.length) + "个新用户，即可领取礼包二！")
      return
    }
    if (type == "礼包三" && newUserList.length < 100) {
      _this.openNotification("您还需邀请" + (100 - newUserList.length) + "个新用户，即可领取礼包三！")
      return
    }

    exchangeGift({ "type": type }).then(res => {
      if (res && res.data && res.data.success) {
        var resData = res.data.data;

        _this.setState({
          "showModal_success": true
        })
        _this.giftGetNewUserListFun()//查询最新100条邀请的用户
        _this.exchangeHistoryFun()//查询领取记录

      } else {
        _this.openNotification("领取失败，请稍后重试！")
      }
    })

  }

  //查询领取记录
  exchangeHistoryFun = () => {
    let _this = this;
    exchangeHistory().then(res => {
      if (res && res.data && res.data.success) {
        var resData = res.data.data;
        resData.map((t, i) => {
          t['index'] = i + 1
        })
        _this.setState({
          "tableData": resData
        })
      } else {
        _this.setState({
          "tableData": []
        })
      }
    })

  }

  render() {
    const { spinning, showModal_success, showModel_recommend, tableData, newUserList, user } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    var recommendUrl = 'https://baodanerp.cn'
    if (user) {
      recommendUrl = 'https://baodanerp.cn/home?recommend=' + user.userId
    }

    var columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width:"50px",
        // render: (text) => <a>{text}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '礼包类型',
        dataIndex: 'type',
        key: 'type',
        width:"100px",
      },
      {
        title: '领取条件',
        dataIndex: 'peopleCount',
        key: 'peopleCount',
        width:"150px",
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text, record) => <>邀请{text}个新用户</>
      },
      {
        title: '礼包详情',
        dataIndex: 'status',
        key: 'status',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
        render: (text, record) => {
          var showText = "1: 10个店铺*1个月;2: 统计选品top100*1个月;3: 图片翻译（通用）一千次;4: 文本翻译（通用）一百万字;5: 图片白底（通用）一百张;6: 平台金币1000个;7: 国产DeepSeek AI服务100次。"
          if (record.type && record.type == "礼包二") {
            showText = "1: 50个店铺*1个月;2: 统计选品top500*1个月;3: 图片翻译（通用）三千次;4: 文本翻译（通用）五百万字;5: 图片白底（通用）一千张;6: 平台金币3000个;7: 国产DeepSeek AI服务200次。"
          } else if (record.type && record.type == "礼包三") {
            showText = "1: 150个店铺*1个月;2: 统计选品top1000*1个月;3: 图片翻译（通用）一万次;4: 文本翻译（通用）一千万字;5: 图片白底（通用）五千张;6: 平台金币10000个;7: 国产DeepSeek AI服务300次。"
          }
          return <span>{showText}</span>
        }
      },
      {
        title: '领取时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
        width:"150px",
      },
    ];


    const bannerData = [
      {
        "img": "/img/banner/banner_gift.png",
        "btn1": { "text": "直接购买", "url": window.location.origin + "/price" }
      },
    ]
    const bannerData_phone = [
      {
        "img": "/img/banner/banner_gift_phone.png",
        "btn1": { "text": "直接购买", "url": window.location.origin + "/price" }
      },
    ]

    // const newUserList = Array.from({ length: 95 }, (_, index) => index)

    var needCount = 10 - newUserList.length % 10
    var needNewUserList = Array.from({ length: needCount }, (_, index) => index)

    return (
      <React.Fragment>
        <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />
        <div className="rootBox  minHeight100vh salesPromotionPage">
          <div className="backgroundWhite" style={{ padding: "30px" }}>
            <Row justify={"center"} align={"middle"} wrap gutter={[16, 16]}>
              <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8} className="" >
                <div className="giftBox">
                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="displayInlineBlock giftTitle">礼包一</div>
                    <div className="displayInlineBlock giftSubtitle">邀请<span className="giftSubtitleNumber">10<span style={{ fontSize: "12px" }}>个</span></span>新用户可领取以下福利</div>
                  </Flex>
                  <div className="giftContent">
                    <div className="giftContentIcon">
                      <Image src="/img/icon_gift.png" width={45} height={45} preview={false}></Image>
                    </div>
                    <div>1: 10个店铺*1个月</div>
                    <div>2: 统计选品top100*1个月</div>
                    <div>3: 图片翻译（通用）一千次</div>
                    <div>4: 文本翻译（通用）一百万字</div>
                    <div>5: 图片白底（通用）一百张</div>
                    <div>6: 平台金币1000个</div>
                    <div>7: 国产DeepSeek AI服务100次</div>
                  </div>

                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="giftMoney colorBlack">总价值：<span className="colorRed fontWeightBold" style={{ fontSize: "24px" }}>￥188.00</span></div>
                    <Button type="primary" style={{ padding: "10px 22px", borderRadius: "0px", background: "#EF1919" }} onClick={() => this.exchangeGiftFun("礼包一")}>免费领取</Button>
                  </Flex>
                </div>
              </Col>
              <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8} className="" >
                <div className="giftBox">
                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="displayInlineBlock giftTitle">礼包二</div>
                    <div className="displayInlineBlock giftSubtitle">邀请<span className="giftSubtitleNumber">30<span style={{ fontSize: "12px" }}>个</span></span>新用户可领取以下福利</div>
                  </Flex>
                  <div className="giftContent">
                    <div className="giftContentIcon">
                      <Image src="/img/icon_gift.png" width={45} height={45} preview={false}></Image>
                    </div>
                    <div>1: 50个店铺*1个月</div>
                    <div>2: 统计选品top500*1个月</div>
                    <div>3: 图片翻译（通用）三千次</div>
                    <div>4: 文本翻译（通用）五百万字</div>
                    <div>5: 图片白底（通用）一千张</div>
                    <div>6: 平台金币3000个</div>
                    <div>7: 国产DeepSeek AI服务200次</div>
                  </div>

                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="giftMoney colorBlack">总价值：<span className="colorRed fontWeightBold" style={{ fontSize: "24px" }}>￥888.00</span></div>
                    <Button type="primary" style={{ padding: "10px 22px", borderRadius: "0px", background: "#EF1919" }} onClick={() => this.exchangeGiftFun("礼包二")}>免费领取</Button>
                  </Flex>
                </div>
              </Col>
              <Col xs={24} sm={10} md={8} lg={8} xl={8} xxl={8} className="" >
                <div className="giftBox">
                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="displayInlineBlock giftTitle">礼包三</div>
                    <div className="displayInlineBlock giftSubtitle">邀请<span className="giftSubtitleNumber">100<span style={{ fontSize: "12px" }}>个</span></span>新用户可领取以下福利</div>
                  </Flex>
                  <div className="giftContent">
                    <div className="giftContentIcon">
                      <Image src="/img/icon_gift.png" width={45} height={45} preview={false}></Image>
                    </div>
                    <div>1: 150个店铺*1个月</div>
                    <div>2: 统计选品top1000*1个月</div>
                    <div>3: 图片翻译（通用）一万次</div>
                    <div>4: 文本翻译（通用）一千万字</div>
                    <div>5: 图片白底（通用）五千张</div>
                    <div>6: 平台金币10000个</div>
                    <div>7: 国产DeepSeek AI服务300次</div>
                  </div>

                  <Flex gap="middle" align="center" justify="space-between" >
                    <div className="giftMoney colorBlack">总价值：<span className="colorRed fontWeightBold" style={{ fontSize: "24px" }}>￥2588.00</span></div>
                    <Button type="primary" style={{ padding: "10px 22px", borderRadius: "0px", background: "#EF1919" }} onClick={() => this.exchangeGiftFun("礼包三")}>免费领取</Button>
                  </Flex>
                </div>
              </Col>
            </Row>

            <div className="giftUserHeaderBox">
              <div className="giftUserTitle">已邀请<span className="colorRed">{newUserList.length}</span>人</div>
              <Row justify={"center"} align={"middle"} wrap gutter={[16, 16]}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18} className="" >
                  <Row justify={"center"} align={"middle"} wrap gutter={[16, 16]}>
                    {
                      newUserList.map((item, index) => {
                        var userName = item.name
                        return <Col xs={{ flex: '10%' }} sm={{ flex: '10%' }} md={{ flex: '10%' }} lg={{ flex: '10%' }} xl={{ flex: '10%' }} xxl={{ flex: '10%' }} className="">
                          <div className="displayInlineBlock">
                            <div>
                              <Avatar size={isPhone ? 30 : 64} src={item.attr2 ? item.attr2 : "/img/user_head.png"}></Avatar>
                            </div>
                            <div className="giftUserName">
                              {userName.substring(0, 2)}**
                            </div>
                          </div>
                        </Col>
                      })
                    }
                    {
                      needNewUserList.map(item => {
                        return <Col xs={{ flex: '20%' }} sm={{ flex: '20%' }} md={{ flex: '10%' }} lg={{ flex: '10%' }} xl={{ flex: '10%' }} xxl={{ flex: '10%' }} className="">
                          <div className="displayInlineBlock">
                            <div>
                              <Avatar size={isPhone ? 30 : 64} src={"/img/white.png"} style={{ border: "1px dashed #0766F4" }}></Avatar>
                            </div>
                            <div className="giftUserName"></div>
                          </div>
                        </Col>
                      })
                    }
                  </Row>
                </Col>
              </Row>

              <div className="giftUserInvitation">
                <Button type="primary" style={{ padding: "12px 32px", borderRadius: "2px" }} onClick={() => this.setStateKeyValue("showModel_recommend", true)}>立即邀请</Button>
              </div>
            </div>

            <div>
              <Flex gap="middle" align="flex-start" justify="space-between" style={{ marginBottom: "15px" }}>
                <div className="giftHistory">领取记录</div>
                <NavLink to="/user/agentInvite" target="_blank">
                  <div className="giftDetail">邀请记录</div>
                </NavLink>

              </Flex>

              <div>
                <Spin tip="查询中..." spinning={spinning}>
                  <Table columns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"} pagination={false} />
                </Spin>
              </div>
            </div>
            <div style={{ marginTop: "28px" }}>
              注释：
            </div>
            <div>
              1、礼品第一项中的店铺，只争对shopee和tiktok shop店铺有效。其他项平台通用。
            </div>
            <div>
              2、50个店铺 * 1个月： 根据您最新订购的店铺数来计算月份。假设您最新订购店铺数为20个店铺，则可领取：20个店铺*3个月（具体月份数=50/最新订购的店铺数，四舍五入）。
            </div>
            <div>
              3、统计选品项：未订购统计选品，或者已经过期，或者已订购的套餐与赠送的套餐一致时生效。
            </div>


          </div>
        </div>


        <Modal
          open={showModal_success}
          onCancel={() => this.setStateKeyValue("showModal_success", false)}
          footer={null}
        >
          <Result
            status="success"
            title="领取成功!"
            subTitle="ERP软件需另外登录，才能生效."
            extra={[
              <NavLink to="/user/profile?refresh=1" target="_blank">
                <Button key="buy" type="primary">前往个人中心</Button>
              </NavLink>
              ,
              <Button key="console" onClick={() => this.setStateKeyValue("showModal_success", false)}>
                关闭
              </Button>
            ]}
          />
        </Modal>

        <Modal title="邀请好友" open={showModel_recommend} footer={null} onCancel={() => this.setStateKeyValue("showModel_recommend", false)}>
          <div className="recommendBox">
            <ShareBox url={recommendUrl}></ShareBox>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(SalesPromotion);